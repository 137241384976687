import ReactGA from 'react-ga';

// **** This is used for legacy GA (Universal)
// **** GTM is used to for GA4.
// TODO: Remove this setup on or after Jul1, 2023
// sets up google analytics based on the code set in the env file
const setupGoogleAnalytics = (): void => {
    const sendAnalytics = process.env.REACT_APP_SEND_ANALYTICS;

    if (sendAnalytics === 'false') {
        console.warn('google analytics not setup as as they are disabled in the environment config');
        return;
    }

    // setup google analytics
    const googleAnalyticsCode = process.env.REACT_APP_GA_CODE;
    if (googleAnalyticsCode) {
        ReactGA.initialize(googleAnalyticsCode);
        ReactGA.pageview(window.location.pathname + window.location.search);
    } else console.error('error googleAnalyticsCode is not defined');
};

export { setupGoogleAnalytics };
