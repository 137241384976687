const getClientStub = (): string | null => {
    let configId = null;
    let parts = window.location.pathname.split('/');

    if (parts.length > 0) {
        configId = parts[parts.length - 1];
        if (configId === '' && parts.length > 2) {
            configId = parts[parts.length - 2];
        }
    }
    return configId;
};

export { getClientStub };
