import { proxy, useSnapshot, subscribe } from 'valtio';

type MetadataStoreType = {
    url: string | null;
};

const urlStore = proxy<MetadataStoreType>({
    url: null,
});

const setUrl = (url: string | null) => {
    urlStore.url = url;
};

export { urlStore, setUrl, useSnapshot, subscribe };
