const LOCAL_STORAGE_KEY = 'landing-video-displayed';

const getDisplayLandingVideo = (clientStub: string) => {
    const key = `${LOCAL_STORAGE_KEY}-${clientStub}`;

    const value = localStorage.getItem(key);

    if (value === 'false') {
        return false;
    }
    return true;
};

const setDisplayLandingVideo = (clientStub: string) => {
    const key = `${LOCAL_STORAGE_KEY}-${clientStub}`;
    localStorage.setItem(key, 'false');
};

export { getDisplayLandingVideo, setDisplayLandingVideo };
