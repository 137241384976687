export interface INamedObject {
    name: string;
    val: object;
}

const registerComponents = (components: Array<INamedObject>) => {
    components.forEach(({ name, val }) => {
        AFRAME.registerComponent(name, val);
    });
};

const registerPrimitives = (primitives: Array<INamedObject>) => {
    primitives.forEach(({ name, val }) => {
        AFRAME.registerPrimitive(name, val);
    });
};

const registerSystems = (systems: Array<INamedObject>) => {
    systems.forEach(({ name, val }) => {
        AFRAME.registerSystem(name, val);
    });
};

export { registerComponents, registerPrimitives, registerSystems };
