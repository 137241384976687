import React, { useMemo } from 'react';
import './loading-screen.scss';
import { CircularProgress } from '@material-ui/core';
import { LoadingScreenConfig, LoadingScreenLogoConfig } from '../../interfaces/configs/loading-screen-config';
import { CSSProperties } from '@material-ui/styles';
import { URL_CONFIG_OVERRIDES } from '../../enums/url-config-overrides';

interface IProps {
    progress?: number;
    configs: Array<LoadingScreenConfig> | null;
}

function LoadingScreen(props: IProps) {
    const { progress, configs } = props;

    const config = useMemo(() => {
        if (!configs || configs.length === 0) return null;
        const params = new URLSearchParams(window.location.search);

        const id = params.get(URL_CONFIG_OVERRIDES.LOADING_SCREEN);
        if (id) {
            const conf = configs.find((c) => c.loadingScreenKey === id);
            if (conf) return conf;
        }
        const conf = configs.find((c) => c.defaultLoadingScreen === true);
        if (conf) return conf;
        return configs[0];
    }, [configs]);

    // uses set config or this default config if none is provided
    const logoConfig: LoadingScreenLogoConfig = config?.logoConfig || {
        url: '/assets/images/visualisar_light.svg',
        width: '30rem',
        opacity: 1,
    };

    // uses set config or this default config if none is provided
    const viewaLogoConfig: LoadingScreenLogoConfig = config?.viewaLogoConfig || {
        url: '/assets/images/viewa_light.svg',
        width: 'min(12.5vw, 12.5vh)',
        opacity: 1,
    };

    // uses set config or this default config if none is provided
    const poweredBy8thWallLogoConfig: LoadingScreenLogoConfig = config?.poweredBy8thWallLogoConfig || {
        url: '/assets/images/powered_by_8th_wall_light.svg',
        width: 'min(36vw, 36vh)',
        opacity: 1,
    };

    // Setup overlay styling.
    let overlayStyle: CSSProperties = {};

    if (config && config.backgroundColor) {
        overlayStyle.background = config.backgroundColor;
        overlayStyle.mixBlendMode = overlayStyle.mixBlendMode ? overlayStyle.mixBlendMode : 'initial';
    }

    return (
        <div
            className="loading-screen"
            style={{
                ...(config && config.backgroundImageUrl
                    ? { backgroundImage: 'url(' + config.backgroundImageUrl + ')' }
                    : {}),
            }}
        >
            <div className="loading-screen-overlay" style={overlayStyle}></div>

            <div className="loading-logo">
                <img
                    src={logoConfig.url}
                    className="logo"
                    alt=""
                    style={{
                        ...(logoConfig.width ? { width: logoConfig.width } : {}),
                        ...(logoConfig.opacity ? { opacity: logoConfig.opacity } : {}),
                    }}
                />
            </div>

            <div className="loading-status">
                {progress && (!config || config.showLoadingPercent) ? (
                    <CircularProgress
                        className="progress-circle"
                        variant="determinate"
                        value={progress}
                        style={{
                            ...(config && config.progressCircleColor ? { color: config.progressCircleColor } : {}),
                        }}
                    />
                ) : (
                    <CircularProgress
                        className="progress-circle"
                        variant="indeterminate"
                        style={{
                            ...(config && config.progressCircleColor ? { color: config.progressCircleColor } : {}),
                        }}
                    />
                )}

                {config?.loadingScreenText && (
                    <span
                        className="loading-text"
                        style={{
                            ...(config.loadingScreenTextColor ? { color: config.loadingScreenTextColor } : {}),
                        }}
                    >
                        {config.loadingScreenText}
                    </span>
                )}
            </div>

            {config?.loadingScreenText && (
                <span
                    className="loading-text"
                    style={{
                        ...(config.loadingScreenTextColor ? { color: config.loadingScreenTextColor } : {}),
                    }}
                >
                    {config.loadingScreenText}
                </span>
            )}

            <div className="loading-footer">
                <div
                    className="powered-by-viewa"
                    style={{
                        ...(viewaLogoConfig.width ? { width: viewaLogoConfig.width } : {}),
                        ...(viewaLogoConfig.opacity ? { opacity: viewaLogoConfig.opacity } : {}),
                    }}
                >
                    <img src={viewaLogoConfig.url} alt="powered by Viewa" />
                </div>

                <div
                    className="powered-by-8th-wall"
                    style={{
                        ...(poweredBy8thWallLogoConfig.width ? { width: poweredBy8thWallLogoConfig.width } : {}),
                        ...(poweredBy8thWallLogoConfig.opacity ? { opacity: poweredBy8thWallLogoConfig.opacity } : {}),
                    }}
                >
                    <img src={poweredBy8thWallLogoConfig.url} alt="powered by 8th wall" />
                </div>
            </div>
        </div>
    );
}

export { LoadingScreen };
