import type aframe from 'aframe';
import { CUSTOM_EVENTS } from '../enums/custom-events';
declare let THREE: any;

const ensureMaterialArray = (material: any) => {
    if (!material) {
        return [];
    }

    if (Array.isArray(material)) {
        return material;
    }

    if (material.materials) {
        return material.materials;
    }

    return [material];
};

const applyEnvMap = (mesh: any, envMap: any) => {
    if (!mesh) return;
    mesh.traverse((node: any) => {
        if (!node.isMesh) {
            return;
        }

        const meshMaterials = ensureMaterialArray(node.material);
        meshMaterials.forEach((material: any) => {
            if (material && !('envMap' in material)) return;
            material.envMap = envMap;
            material.needsUpdate = true;
        });
    });
};

interface ICubeMapRealtimeComponent extends Partial<aframe.Component> {
    // custom properties and functions added specificity for this component
    onXrLoaded: any;
}

// based on code from https://www.8thwall.com/8thwall/cubemap-aframe/master/cubemap-realtime.js
// used to add realtime reflections to a model
const CubeMapRealtimeComponent: ICubeMapRealtimeComponent = {
    schema: {},
    init() {
        this.onXrLoaded = this.onXrLoaded.bind(this);

        // can only setup reflections once 8th wall has finished setting up

        // @ts-ignore
        if (window.XR8) {
            this.onXrLoaded();
        } else {
            window.addEventListener('xrloaded', this.onXrLoaded, { once: true, passive: true });
        }
    },
    onXrLoaded() {
        if (this.el && this.el.sceneEl) {
            const scene = this.el.sceneEl;
            const camTexture_ = new THREE.Texture();
            const refMat = new THREE.MeshBasicMaterial({
                side: THREE.DoubleSide,
                color: 0xffffff,
                map: camTexture_,
            });

            const renderTarget = new THREE.WebGLCubeRenderTarget(256, {
                format: THREE.RGBFormat,
                generateMipmaps: true,
                minFilter: THREE.LinearMipmapLinearFilter,
                encoding: THREE.sRGBEncoding,
            });

            // cubemap scene

            const cubeMapScene = new THREE.Scene();
            const cubeCamera = new THREE.CubeCamera(1, 1000, renderTarget);
            const sphere = new THREE.SphereGeometry(100, 15, 15);
            const sphereMesh = new THREE.Mesh(sphere, refMat);
            sphereMesh.scale.set(-1, 1, 1);
            sphereMesh.rotation.set(Math.PI, -Math.PI / 2, 0);
            cubeMapScene.add(sphereMesh);
            // @ts-ignore
            window.XR8.XrController.configure({ enableLighting: true });
            // @ts-ignore
            window.XR8.addCameraPipelineModule({
                name: 'cubemap-process',
                onUpdate: () => {
                    cubeCamera.update(scene.renderer, cubeMapScene);
                },

                onProcessCpu: ({ frameStartResult }: { frameStartResult: any }) => {
                    const { cameraTexture } = frameStartResult;
                    // force initialization
                    const texProps = scene.renderer.properties.get(camTexture_);
                    texProps.__webglTexture = cameraTexture;
                },
            });

            this.el.addEventListener('model-loaded', () => {
                if (this.el) {
                    applyEnvMap(this.el.object3D, cubeCamera.renderTarget.texture);
                }
            });
            // custom event sent by the aframe-scene component when a material is changed on the model
            this.el.addEventListener(CUSTOM_EVENTS.MODEL_3D_CONTROLLER_MATERIAL_CHANGE, () => {
                if (this.el) {
                    applyEnvMap(this.el.object3D, cubeCamera.renderTarget.texture);
                }
            });
        }
    },
};

export { CubeMapRealtimeComponent };
