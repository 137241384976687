import { useState, useEffect } from 'react';

import { CustomFormConfig } from '../../interfaces/configs/custom-form-configs/custom-form-config';
import './form-submission-confirmation.scss';

interface IProps {
    className?: string;
    formConfig: CustomFormConfig;
    onClose?: () => void;
    animationDuration?: number;
}
const FormSubmissionConfirmation = (props: IProps) => {
    let { className, formConfig, onClose } = props;

    const [panelOpen, setPanelOpen] = useState(false);

    const [timeoutId, setTimeoutId] = useState<null | NodeJS.Timeout>(null);

    // if you change this value you should also change the value in the relevant scss file
    const animationDuration = 1;

    // current display time is set to 3 seconds
    const displayTime =
        formConfig.formConfirmationConfig?.displayTime === null ||
        formConfig.formConfirmationConfig?.displayTime === undefined
            ? 3
            : formConfig.formConfirmationConfig?.displayTime;

    const style = {
        ...(formConfig?.backgroundColor ? { backgroundColor: formConfig?.backgroundColor } : {}),
        ...(formConfig?.textColor ? { color: formConfig?.textColor } : {}),
    };

    const startCloseAnimation = () => {
        clearTimeoutId();

        setPanelOpen(false);
        // sets a timeout to call the onClose when the closing animation has finished
        setTimeout(() => {
            if (onClose) onClose();
        }, animationDuration * 1000);
    };

    const clearTimeoutId = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    // use effect is only run a single time when model is loaded
    useEffect(() => {
        setPanelOpen(true);
        // sets a timeout to hide the panel
        const currentTimeout = setTimeout(() => {
            startCloseAnimation();

            // uses display time and animation time as it
            // waits for the panel to be fully visible before starting countdown of display time
        }, (displayTime + animationDuration) * 1000);

        // sets the timeout in case it needs to be canceled
        setTimeoutId(currentTimeout);

        // returns cleanup function
        return () => {
            clearTimeoutId();
        };
        // no dependencies are used as this is only meant to run once at load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        startCloseAnimation();
    };

    const line1Text = formConfig.formConfirmationConfig?.textLine1 || 'THANK YOU!';
    const line2Text = formConfig.formConfirmationConfig?.textLine2 || 'YOUR DETAILS HAVE BEEN SUBMITTED';

    return (
        <div className={`darken-background ${panelOpen ? 'panel-open' : ''} handleClick`} onClick={handleClick}>
            <div
                className={`form-submission-confirmation ${className ? className : ''} ${
                    formConfig.logoUrl ? '' : 'no-logo'
                }`}
                style={style}
            >
                {formConfig.logoUrl && (
                    <div className="logo-wrapper">
                        <img className="logo" alt="" src={formConfig.logoUrl} />
                    </div>
                )}

                <div className="line-1-wrapper">
                    <span>{line1Text}</span>
                </div>
                <div className="line-2-wrapper">
                    <span>{line2Text}</span>
                </div>
            </div>
        </div>
    );
};

export { FormSubmissionConfirmation };
