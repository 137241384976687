import React, { ReactElement } from 'react';

interface IProps {
    placeholder?: string;
    id?: string;
    required?: boolean;
    value?: any;
    onChange?: any;
}

function TextInput({ placeholder, id, required, value, onChange }: IProps): ReactElement {
    return (
        <input
            type="text"
            name={id}
            id={id}
            className="rounded-lg text-base"
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChange}
        />
    );
}

export default TextInput;
