//uses 8th walls XR8 to determine if the device is supported
const isDeviceCompatible = async () => {
    return new Promise<boolean>((resolve) => {
        // @ts-ignore
        if (window.XR8) {
            // @ts-ignore
            resolve(window.XR8.XrDevice.isDeviceBrowserCompatible());
        } else {
            window.addEventListener(
                'xrloaded',
                () => {
                    // @ts-ignore
                    resolve(window.XR8.XrDevice.isDeviceBrowserCompatible());
                },
                { once: true, passive: true }
            );
        }
    });
};

export { isDeviceCompatible };
