export enum FORM_DISPLAY_TYPE {
    // displays form when user tries to load page
    WEBPAGE_LOAD = 'WebpageLoad',
    // displays form when user tries to take a screenshot
    ON_SCREENSHOT = 'onScreenshot',
    // when the user presses the share option, !!!WARNING NOT CURRENTLY IMPLEMENTED!!!,
    ON_SHARE = 'onShare',
    // displayed by user interaction with a button
    CUSTOM = 'custom',
}
