import type aframe from 'aframe';
import type three from 'three';
import { CUSTOM_EVENTS } from '../enums/custom-events';
import { PLACEMENT_SURFACE_TYPE } from '../enums/placement-surface-type';

declare let THREE: any;

interface ICustomHoldDragComponent extends Partial<aframe.Component> {
    // custom properties added specificity for this component

    threeCamera?: THREE.Camera;
    ground?: aframe.Entity;
    wall?: aframe.Entity;
    camera?: aframe.Entity;
    boundingBox?: aframe.Entity;

    internalState?: {
        fingerDown: boolean;
        dragging: boolean;
        distance: number;
        startDragTimeout: any;
        raycaster: THREE.Raycaster;
        positionRaw: any;
        lastIntersectionPoint: THREE.Vector3 | null;
    };

    // custom functions
    fingerDown: any;
    fingerUp: any;
    fingerMove: any;
    startDrag: any;
    onWallPlaced: any;

    updateBoundingBoxSize: any;
}

// component is an edited version of component from https://github.com/8thwall/web/blob/master/xrextras/src/aframe/xr-components.js
// used to add touch drag movement to model
const CustomHoldDragComponent: ICustomHoldDragComponent = {
    schema: {
        cameraId: { default: 'camera' },
        groundId: { default: 'ground' },
        wallId: { default: 'wall' },
        dragDelay: { default: 0 },
        boundingBoxEnabled: { default: true },
        modelHidden: { default: false },
        dragYOffset: { default: 0.5 },
        // valid values are 'Floor', 'Wall' and 'Ceiling' from PLACEMENT_SURFACE_TYPE
        placementSurfaceType: { default: 'Floor', type: 'string' },
    },
    init() {
        this.camera = document.getElementById(this.data.cameraId) as aframe.Entity;
        this.threeCamera = this.camera.getObject3D('camera') as THREE.Camera;
        this.ground = document.getElementById(this.data.groundId) as aframe.Entity;

        this.fingerDown = this.fingerDown.bind(this);
        this.startDrag = this.startDrag.bind(this);
        this.fingerMove = this.fingerMove.bind(this);
        this.fingerUp = this.fingerUp.bind(this);
        this.onWallPlaced = this.onWallPlaced.bind(this);

        this.internalState = {
            fingerDown: false,
            dragging: false,
            distance: 0,
            startDragTimeout: null,
            raycaster: new THREE.Raycaster(),
            positionRaw: null,
            lastIntersectionPoint: null,
        };
        if (this.el && this.el.sceneEl) {
            this.el.addEventListener('mousedown', this.fingerDown);
            this.el.sceneEl.addEventListener('onefingermove', this.fingerMove);
            this.el.sceneEl.addEventListener('onefingerend', this.fingerUp);
            this.el.classList.add('cantap'); // Needs "objects: .cantap" attribute on raycaster.

            this.el.addEventListener('model-loaded', () => {
                if (this.el && this.data.boundingBoxEnabled) {
                    if (this.boundingBox === undefined) {
                        // creates the bounding box as it doesn't exist yet
                        this.boundingBox = document.createElement('a-box');
                        if (this.data.modelHidden === false) {
                            this.boundingBox.classList.add('cantap');
                        }
                        this.el.appendChild(this.boundingBox);
                        this.updateBoundingBoxSize(true);
                    } else {
                        this.updateBoundingBoxSize(false);
                    }
                }
            });

            // gets wall in case it has already been placed
            this.wall = document.getElementById(this.data.wallId) as aframe.Entity;

            // adds event listener for when wall is placed
            const scene = document.querySelector('a-scene');
            if (scene) {
                // the wall-placed event is fired by the custom-place-wall-component
                scene.addEventListener(CUSTOM_EVENTS.WALL_PLACED, this.onWallPlaced);
            }
        }
    },
    tick() {
        let target = this.ground;
        if (this.data.placementSurfaceType === PLACEMENT_SURFACE_TYPE.WALL) {
            target = this.wall;
        }
        if (this.camera && target) {
            if (this.internalState && this.internalState.dragging) {
                let desiredPosition = null;
                if (this.internalState.positionRaw) {
                    // gets screen position in correct coordinate space
                    const screenPositionX = (this.internalState.positionRaw.x / document.body.clientWidth) * 2 - 1;
                    const screenPositionY = (this.internalState.positionRaw.y / document.body.clientHeight) * 2 - 1;
                    const screenPosition = new THREE.Vector2(screenPositionX, -screenPositionY);

                    this.threeCamera = this.threeCamera || (this.camera.getObject3D('camera') as THREE.Camera);

                    // casts ray
                    this.internalState.raycaster.setFromCamera(screenPosition, this.threeCamera);
                    const intersects = this.internalState.raycaster.intersectObject(target.object3D, true);

                    // if something is hit
                    if (intersects.length > 0) {
                        const intersect: three.Intersection = intersects[0];
                        this.internalState.distance = intersect.distance;
                        desiredPosition = intersect.point.clone();
                        this.internalState.lastIntersectionPoint = intersect.point.clone();
                    } else this.internalState.lastIntersectionPoint = null;
                }

                // used when the user isn't pressing on the invisible wall / floor, in this case it just using the center of the screen for the target point
                if (!desiredPosition) {
                    // only done for floor placement as it can break wall placement by moving the model behind th wall
                    if (this.data.placementSurfaceType === PLACEMENT_SURFACE_TYPE.FLOOR) {
                        desiredPosition = this.camera.object3D.localToWorld(
                            new THREE.Vector3(0, 0, -this.internalState.distance)
                        );
                    }
                }
                if (desiredPosition) {
                    // adds drag offset if applicable
                    if (this.data.placementSurfaceType === PLACEMENT_SURFACE_TYPE.FLOOR) {
                        desiredPosition.y += this.data.dragYOffset;
                    } else if (this.data.placementSurfaceType === PLACEMENT_SURFACE_TYPE.CEILING) {
                        // as the model needs to move down instead of up from the ceiling the offset is applied in reverse
                        desiredPosition.y -= this.data.dragYOffset;
                    }
                    // uses a slight lerp animation to make the movement smooth
                    if (this.el) {
                        this.el.object3D.position.lerp(desiredPosition, 0.2);
                    }
                }
            }
        }
    },
    update() {
        if (this.data && this.boundingBox) {
            // if the model hidden value is changed the cantap class is added / removed from the invisible bounding box to enable / disable interaction with it
            if (this.data.modelHidden === true) {
                this.boundingBox.classList.remove('cantap');
            } else this.boundingBox.classList.add('cantap');
        }
    },
    remove() {
        if (this.el && this.el.sceneEl) {
            this.el.removeEventListener('mousedown', this.fingerDown);
            this.el.sceneEl.removeEventListener('onefingermove', this.fingerMove);
            this.el.sceneEl.removeEventListener('onefingerend', this.fingerUp);
            if (this.internalState && this.internalState.fingerDown) {
                this.fingerUp();
            }
        }
    },
    fingerDown(event: any): void {
        if (this.internalState) {
            this.internalState.fingerDown = true;
            this.internalState.startDragTimeout = setTimeout(this.startDrag, this.data.dragDelay);
            this.internalState.positionRaw = event.detail.positionRaw;
        }
    },
    startDrag(event: any): void {
        if (this.el) {
            if (this.internalState && this.camera) {
                if (!this.internalState.fingerDown) {
                    return;
                }
                this.internalState.dragging = true;
                this.internalState.distance = this.el.object3D.position.distanceTo(this.camera.object3D.position);
            }
        }
    },
    fingerMove(event: any): void {
        if (this.internalState) {
            this.internalState.positionRaw = event.detail.positionRaw;
        }
    },
    fingerUp(event: any): void {
        if (this.internalState && this.el) {
            this.internalState.fingerDown = false;
            clearTimeout(this.internalState.startDragTimeout);

            this.internalState.positionRaw = null;

            if (
                this.internalState.dragging &&
                this.internalState.lastIntersectionPoint &&
                // as wall mde doesn't use a y offset no animation is needed
                this.data.placementSurfaceType !== PLACEMENT_SURFACE_TYPE.WALL
            ) {
                const endPosition = this.el.object3D.position.clone();
                // plays drop animation
                this.el.setAttribute('animation__drop', {
                    property: 'position',
                    to: `${endPosition.x} ${this.internalState.lastIntersectionPoint.y} ${endPosition.z}`,
                    dur: 300,
                    easing: 'easeOutQuad',
                });
            }
            this.internalState.lastIntersectionPoint = null;
            this.internalState.dragging = false;
        }
    },
    onWallPlaced() {
        this.wall = document.getElementById('wall') as aframe.Entity;
    },
    updateBoundingBoxSize(firstUpdate: boolean): void {
        if (this.el && this.boundingBox) {
            // compute bounding box size
            const mesh = this.el.object3D;

            // detach the bounding box if not the first update
            // otherwise the bounding box gets included in the calculation of the bounding box as it is set as a child of the model
            if (!firstUpdate && this.boundingBox.object3D.parent) {
                this.boundingBox.object3D.parent.remove(this.boundingBox.object3D);
            }

            // toggleMorphAttributes allows bounding box calculation without morphs, currently disabled
            // toggleMorphAttributes(mesh, false);

            /*
             * sets the mesh position and rotation to 0 for bounding box calculation (1 for scale)
             * this is done as the bounding box calculation doesn't take into account position rotation or scale
             * this is done instead of transformation matrix/inverse transformation matrix
             * as a transformation matrix doesn't seem to work (at least when tried previously)
             */

            // stores temp values
            const tempPos: three.Vector3 = new THREE.Vector3().copy(mesh.position);
            const tempRot: three.Euler = new THREE.Euler().copy(mesh.rotation);
            const tempScale: three.Vector3 = new THREE.Vector3().copy(mesh.scale);

            // resets values
            mesh.position.set(0, 0, 0);
            mesh.rotation.set(0, 0, 0);
            mesh.scale.set(1, 1, 1);

            // calculates bounding box
            let boundingBoxSize: three.Box3 = new THREE.Box3().setFromObject(mesh, true);

            // sets the position and rotation back
            mesh.position.copy(tempPos);
            mesh.rotation.copy(tempRot);
            mesh.scale.copy(tempScale);

            let size: three.Vector3 = boundingBoxSize.getSize(new THREE.Vector3());
            let center: three.Vector3 = boundingBoxSize.getCenter(new THREE.Vector3());
            // toggleMorphAttributes(mesh, true);

            // adds the bounding box back as a child of the model
            if (!firstUpdate) {
                this.el.object3D.add(this.boundingBox.object3D);
            }

            //setup bounding box mesh
            this.boundingBox.setAttribute('depth', size.z);
            this.boundingBox.setAttribute('width', size.x);
            this.boundingBox.setAttribute('height', size.y);
            this.boundingBox.setAttribute('position', `${center.x} ${center.y} ${center.z}`);
            this.boundingBox.setAttribute('shadow', { receive: false });
            this.boundingBox.setAttribute('visible', false);
            this.boundingBox.setAttribute('material', 'transparent: true; opacity: 0.0;');
            // uncomment the following 2 lines to make the bounding box visible (debugging)
            // this.boundingBox.setAttribute('material', 'transparent: true; opacity: 0.5;');
            // this.boundingBox.setAttribute('visible', true);
        }
    },
};

// taken from example here https://discourse.threejs.org/t/oversized-bounding-boxes-for-example-models/24029/2
// const toggleMorphAttributes = (node: three.Object3D, restore: boolean): void => {
//     node.traverse((c) => {
//         if (c.type === 'Mesh') {
//             const mesh: three.Mesh = c as three.Mesh;
//             if (mesh.geometry) {
//                 if (restore) {
//                     mesh.geometry.morphAttributes = mesh.geometry.userData.morphAttributes || {};
//                     delete mesh.geometry.userData.morphAttributes;
//                 } else {
//                     mesh.geometry.userData.morphAttributes = mesh.geometry.morphAttributes;
//                     mesh.geometry.morphAttributes = {};
//                 }
//                 mesh.geometry.boundingBox = null;
//             }
//         }
//     });
// };

export { CustomHoldDragComponent };
