import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import TagManager from 'react-gtm-module';

const { REACT_APP_SEND_ANALYTICS, REACT_APP_GTM_CODE } = process.env;

if (REACT_APP_SEND_ANALYTICS !== 'false' && REACT_APP_GTM_CODE) {
    TagManager.initialize({ gtmId: REACT_APP_GTM_CODE });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
