import './custom-form.scss';
import React, { useState, ReactElement } from 'react';

import CustomFormCheckbox from '../generic/checkbox/checkbox';
import CustomFormSelect from '../generic/select/select';
import CustomFormTextInput from '../generic/text-input/text-input';
import CustomFormButton from '../generic/button/button';

import { CustomFormConfig } from '../../interfaces/configs/custom-form-configs/custom-form-config';
import { FormItem } from '../../interfaces/configs/custom-form-configs/form-item';
import { FORM_ITEM_TYPE } from '../../enums/form-item-type';
import { FORM_SCREENSHOT_KEY } from '../../enums/form-unique-keys';

interface IProps {
    currentModelName?: string;
    clientStub: string;
    formConfig: CustomFormConfig;
    onSubmit?: (formState: any) => void;
    onCancel?: () => void;
    customStyles: any;
}

interface FormElementData {
    value: boolean | string | number;
    changed: boolean;
    required: boolean;
    requiredTrue?: boolean;
}

function CustomForm({ formConfig, onSubmit, onCancel, customStyles }: IProps): ReactElement {
    const calculateInitialState = () => {
        const state: any = {};
        formConfig?.formItems?.forEach((formItem: FormItem) => {
            switch (formItem.formItemType) {
                case FORM_ITEM_TYPE.INPUT:
                    {
                        const s: FormElementData = {
                            value: formItem.inputData.defaultValue,
                            required: formItem.inputData.required,
                            changed: false,
                        };
                        state[formItem.inputData.key] = s;
                    }
                    break;
                case FORM_ITEM_TYPE.TEXTBOX:
                    {
                        const s: FormElementData = {
                            value: formItem.textboxData.defaultValue,
                            required: formItem.textboxData.required,
                            changed: false,
                        };
                        state[formItem.textboxData.key] = s;
                    }
                    break;
                case FORM_ITEM_TYPE.SELECT:
                    {
                        const s: FormElementData = {
                            value: formItem.selectData.defaultValue,
                            required: formItem.selectData.required,
                            changed: false,
                        };
                        state[formItem.selectData.key] = s;
                    }
                    break;
                case FORM_ITEM_TYPE.CHECKBOX:
                    {
                        const s: FormElementData = {
                            value: formItem.checkboxData.defaultValue,
                            required: formItem.checkboxData.required,
                            changed: false,
                            requiredTrue: formItem.checkboxData.requiredTrue,
                        };
                        state[formItem.checkboxData.key] = s;
                    }
                    break;
                case FORM_ITEM_TYPE.SUBMIT_SCREENSHOT:
                    const s: FormElementData = {
                        value: formItem.submitScreenshotData.defaultValue,
                        required: formItem.submitScreenshotData.required,
                        changed: false,
                        requiredTrue: formItem.submitScreenshotData.requiredTrue,
                    };
                    state[FORM_SCREENSHOT_KEY] = s;
                    break;
                default:
                    break;
            }
        });
        return state;
    };

    const [formState, setFormState] = useState<any>(calculateInitialState());

    const onFormValueChange = (key: string, value: any) => {
        const newFormState = { ...formState };
        newFormState[key].value = value;
        newFormState[key].changed = true;
        setFormState(newFormState);
    };

    const formItems = formConfig?.formItems?.map((formItem: FormItem, index: number) => {
        switch (formItem.formItemType) {
            case FORM_ITEM_TYPE.CHECKBOX:
                return (
                    <CustomFormCheckbox
                        key={index}
                        id={formItem.checkboxData.key}
                        labelText={formItem.checkboxData.displayText}
                        checked={formState[formItem.checkboxData.key].value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value: boolean = e.target.checked;
                            onFormValueChange(formItem.checkboxData.key, value);
                        }}
                    />
                );
            case FORM_ITEM_TYPE.SUBMIT_SCREENSHOT:
                return (
                    <CustomFormCheckbox
                        key={index}
                        id={FORM_SCREENSHOT_KEY}
                        labelText={formItem.submitScreenshotData.displayText}
                        checked={formState[FORM_SCREENSHOT_KEY].value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value: boolean = e.target.checked;
                            onFormValueChange(FORM_SCREENSHOT_KEY, value);
                        }}
                    />
                );
            case FORM_ITEM_TYPE.TEXTBOX:
                return <></>;
            case FORM_ITEM_TYPE.INPUT:
                return (
                    <CustomFormTextInput
                        key={index}
                        id={formItem.inputData.key}
                        placeholder={formItem.inputData.displayText}
                        value={formState[formItem.inputData.key].value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value;
                            onFormValueChange(formItem.inputData.key, value);
                        }}
                    />
                );
            case FORM_ITEM_TYPE.SELECT:
                return (
                    <CustomFormSelect
                        key={index}
                        id={formItem.selectData.key}
                        optionValues={formItem.selectData.values}
                        placeholder={formItem.selectData.displayText}
                        value={formState[formItem.selectData.key].value}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                            const value = e.target.value;
                            onFormValueChange(formItem.selectData.key, value);
                        }}
                    />
                );
        }
        return null;
    });

    const canSubmit =
        Object.values(formState).findIndex(
            (s: any) => s.required && (s.changed === false || (s?.requiredTrue === true && s.value !== true))
        ) === -1;

    return (
        <div className={`${customStyles.root} antialiased`}>
            <div className="flex flex-col p-6 space-y-4 mx-auto max-w-md">
                {
                    // Logo / Header
                }
                {formConfig.logoUrl && (
                    <div className={`flex justify-center`}>
                        <img className="h-22 w-50" src={formConfig.logoUrl} alt="Logo" />
                    </div>
                )}

                {
                    // Headline
                }
                {formConfig.headline && (
                    <div className={customStyles.headline}>
                        <h1 className="text-lg text-center uppercase">{formConfig.headline}</h1>
                    </div>
                )}

                {
                    // Inputs
                }
                <div className={`flex flex-col space-y-6 text-base ${customStyles.formInputs} `}>{formItems}</div>

                {
                    // Buttons
                }
                <div className={`flex flex-col space-y-4 pt-4 items-center`}>
                    <CustomFormButton
                        label={formConfig.submitButtonText ? formConfig.submitButtonText : 'SUBMIT'}
                        customStyles={`${customStyles.submitButton} ${!canSubmit ? customStyles.disabled : ''}`}
                        onClick={onSubmit ? () => onSubmit(formState) : null}
                        disabled={!canSubmit}
                    />

                    <CustomFormButton
                        label={formConfig.backButtonText ? formConfig.backButtonText : 'BACK'}
                        customStyles={customStyles.cancelButton}
                        onClick={onCancel}
                    />
                </div>
            </div>
        </div>
    );
}

export default CustomForm;
