// custom events used within the app
export enum CUSTOM_EVENTS {
    // fired when animation is stopped
    ANIMATION_CONTROLLER_STOP = 'stop',
    // fired when animation is started
    ANIMATION_CONTROLLER_START = 'start',
    // fired at each loop of the animation
    ANIMATION_CONTROLLER_LOOP = 'loop',

    // fired when the entire material is changed as when this occurs the dynamic environment map needs to be re applied (if enabled)
    MODEL_3D_CONTROLLER_MATERIAL_CHANGE = 'material-change',
    // fired to notify about the loading progress
    MODEL_3D_CONTROLLER_LOADING_PROGRESS = 'loading-progress',
    // when an error occurs loading the model
    MODEL_3D_CONTROLLER_LOADING_ERROR = 'loading-error',
    // fired to notify when the model is hidden
    MODEL_3D_CONTROLLER_MODEL_HIDDEN = 'model-hidden',
    // fired to notify when the model is shown
    MODEL_3D_CONTROLLER_MODEL_SHOWN = 'model-shown',
    // fired when texture(s) for material start loading
    MODEL_3D_CONTROLLER_MATERIAL_LOADING = 'material-loading',
    // fired when texture(s) for material finished loading
    MODEL_3D_CONTROLLER_MATERIAL_LOADED = 'material-loaded',

    // help tour event fired when stage in help tour changes
    HELP_TOUR_EVENT = 'help-tour-event',

    // emitted by the custom-place-wall-component
    WALL_PLACED = 'wall-placed',
}
