import React, { lazy, Suspense, useEffect, useState } from 'react';
import WebFont from 'webfontloader';

import './App.css';

// Aframe components
import { CursorComponent } from './aframe-components/cursor-component';
import { CustomHoldDragComponent } from './aframe-components/custom-hold-drag-component';
import { CustomTwoFingerRotateComponent } from './aframe-components/custom-two-finger-rotate-component';
import { CustomPlaceWallComponent } from './aframe-components/custom-place-wall-component';
import { CustomPinchScaleComponent } from './aframe-components/custom-pinch-scale-component';
import { CubeMapRealtimeComponent } from './aframe-components/cube-map-realtime-component';
import { XRLightComponent, XRLightSystem } from './aframe-components/xr-light-component';

// model definition files
import { AppConfig } from './interfaces/configs/app-config';
import { SubMenuNode } from './interfaces/sub-menu-node';

import { FORM_DISPLAY_TYPE } from './enums/form-display-time';
// import { DEFAULT_ASSETS } from './enums/default-assets';

// react components
import { LoadingScreen } from './components/loading-screen/loading-screen';
import { CustomFormPage } from './components/custom-form-page/custom-form-page';
import { FullScreenError } from './components/full-screen-error/full-screen-error';

//helpers
import { getClientStub } from './helpers/get-client-stub';
import { getConfigByClientStub } from './helpers/get-config-by-client-stub';
import { applyHotlinkOverrides } from './helpers/apply-url-config-overrides';
import { sendAnalytics } from './helpers/analytics/send-analytics';
import { customFormCompleted } from './helpers/custom-form-completed';
import { createCustomMenuListNode } from './helpers/create-custom-menu-list-node';
import { addIosPopupCustomText } from './helpers/add-ios-popup-custom-text';
import { createCssClass } from './helpers/create-css-style';
import { setBodyStyles } from './helpers/set-body-styles';
import { setupGoogleAnalytics } from './helpers/analytics/setup-google-analytics';
import { LandingPage } from './components/landing-page-modal/landing-page-modal';
import { isDeviceCompatible } from './helpers/is-mobile-devices';
import { iosBitmapFix } from './helpers/ios-bitmap-fix';
import { getDisplayLandingVideo, setDisplayLandingVideo } from './helpers/get-display-landing-video';
import { StyleOverrideLoader } from './components/style-override-loader/style-override-loader';
import { registerComponents, registerSystems } from './helpers/register-aframe-components';
import { ICON_STYLE } from './enums/icon-style';

const DisplayController = lazy(() => import('./components/display-controller/display-controller'));

// Environment Variables
const { NODE_ENV } = process.env;

function App() {
    const [config, setConfig] = useState<AppConfig | null>(null);
    const [configError, setConfigError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showCustomForm, setShowCustomForm] = useState<boolean>(false);

    const [showLandingPage, setShowLandingPage] = useState<boolean | null>(null);

    useEffect(() => {
        const configId = getClientStub();
        setupGoogleAnalytics();

        //sets up aframe components and system for a-frame
        registerComponents([
            {
                name: 'cursor-component',
                val: CursorComponent,
            },
            {
                name: 'custom-hold-drag-component',
                val: CustomHoldDragComponent,
            },
            {
                name: 'custom-two-finger-rotate-component',
                val: CustomTwoFingerRotateComponent,
            },
            {
                name: 'custom-place-wall-component',
                val: CustomPlaceWallComponent,
            },
            {
                name: 'custom-pinch-scale-component',
                val: CustomPinchScaleComponent,
            },
            {
                name: 'cube-map-realtime-component',
                val: CubeMapRealtimeComponent,
            },
            {
                name: 'xr-light',
                val: XRLightComponent,
            },
        ]);
        registerSystems([
            {
                name: 'xr-light',
                val: XRLightSystem,
            },
        ]);

        // If someone comes in with no client instance, redirect to homepage.
        if (configId?.length === 0 && NODE_ENV !== 'development') {
            window.location.replace('https://www.viewa.com/?utm=considar');
        }

        iosBitmapFix();

        if (configId) {
            getConfigByClientStub(configId).then(async (response) => {
                if (response.json !== null) {
                    console.log({
                        id: configId,
                        response: response,
                        json: response.json,
                    });
                    let conf = response.json as AppConfig;

                    // skips the video when a hotlink or url params are used
                    const skipVideoPromise = applyHotlinkOverrides(conf);
                    // Run 8th Wall compatibility check
                    const deviceCompatiblePromise = isDeviceCompatible();

                    // TODO: add way to validate config
                    setConfig(conf);
                    if (conf.analyticsTrackingCode) {
                        sendAnalytics(conf.analyticsTrackingCode, 'pageload');
                    }
                    // shows custom form
                    if (
                        conf.customForm &&
                        conf.customForm.displayTime === FORM_DISPLAY_TYPE.WEBPAGE_LOAD &&
                        !customFormCompleted(conf.customForm.localStorageKey)
                    ) {
                        setShowCustomForm(true);
                    }
                    if (conf.customModelList) {
                        // as customMenuList doesn't have the parent node (as it's a computed value)
                        // another object is used in it's place CustomModelListNode that has the parent node as an easy to access property
                        const rootNode: SubMenuNode = createCustomMenuListNode(
                            conf.customModelList,
                            conf.subMenuDefinition
                        );
                        conf.customModelList.startingList = rootNode;
                    }
                    // here is where changes are made to the config

                    // updates the customMenuList for the models
                    conf.modelData.forEach((model) => {
                        // as customMenuList doesn't have the parent node (as it's a computed value)
                        // another object is used in it's place CustomModelListNode that has the parent node as an easy to access property
                        if (model.materialOptions) {
                            model.materialOptions.forEach((materialOption) => {
                                if (materialOption.customMaterialList) {
                                    const rootNode: SubMenuNode = createCustomMenuListNode(
                                        materialOption.customMaterialList,
                                        conf.subMenuDefinition
                                    );
                                    materialOption.customMaterialList.startingList = rootNode;
                                }
                            });
                        }
                    });

                    //it sets the model specific data that has been set at the app level
                    // e.g.adding in customButtonConfig for each model from the global customButtonConfig
                    if (conf.customButtonConfigs) {
                        conf.modelData.forEach((model) => {
                            if (model.customButtonConfigs === null) {
                                model.customButtonConfigs = conf.customButtonConfigs;
                            }
                        });
                    }
                    if (conf.placementSurfaceType) {
                        conf.modelData.forEach((model) => {
                            if (model.placementSurfaceType === null) {
                                model.placementSurfaceType = conf.placementSurfaceType;
                            }
                        });
                    }
                    if (conf.disclaimerConfig) {
                        conf.modelData.forEach((model) => {
                            if (model.disclaimerConfig === null) {
                                model.disclaimerConfig = conf.disclaimerConfig;
                            }
                        });
                    }
                    // sets css variable as it's used to set icon styles
                    if (conf.stylesConfig.iconStyle === ICON_STYLE.OUTLINED) {
                        document.documentElement.style.cssText = "--material-icon-style: 'Material Icons Outlined'";
                    }
                    if (conf.stylesConfig.typography) {
                        const styles: any = {
                            ...(conf.stylesConfig.typography.fontSize
                                ? {
                                      'font-size': conf.stylesConfig.typography.fontSize,
                                  }
                                : {}),
                            ...(conf.stylesConfig.typography.fontFamily
                                ? {
                                      'font-family': conf.stylesConfig.typography.fontFamily,
                                  }
                                : {}),
                        };
                        if (conf.stylesConfig.typography.fontFamily) {
                            WebFont.load({
                                google: {
                                    families: [conf.stylesConfig.typography.fontFamily],
                                },
                                // shows error if family isn't valid
                                fontinactive: (familyName: string, fvd: string) => {
                                    console.error(
                                        `font-family with name "${familyName}" and Font Variation Description "${fvd}" could not be loaded`
                                    );
                                },
                            });
                        }
                        setBodyStyles(styles);
                    }
                    if (conf.stylesConfig.iosPopupConfig) {
                        createCssClass(
                            'prompt-box-8w',
                            `{
							background-color: ${conf.stylesConfig.iosPopupConfig.backgroundColor} !important;
							color: ${conf.stylesConfig.iosPopupConfig.textColor} !important;
						}`
                        );
                        createCssClass(
                            'prompt-button-8w',
                            `{
							background-color: ${conf.stylesConfig.iosPopupConfig.secondaryButtonColor} !important;
							color: ${conf.stylesConfig.iosPopupConfig.textColor} !important;
						}`
                        );
                        createCssClass(
                            'button-primary-8w',
                            `{
							background-color: ${conf.stylesConfig.iosPopupConfig.primaryButtonColor} !important;
							color: ${conf.stylesConfig.iosPopupConfig.textColor} !important;
						}`
                        );
                        if (conf.stylesConfig.iosPopupConfig.customText) {
                            addIosPopupCustomText(
                                conf.stylesConfig.iosPopupConfig.customText.text,
                                conf.stylesConfig.iosPopupConfig.customText.continueButtonText,
                                conf.stylesConfig.iosPopupConfig.customText.cancelButtonText
                            );
                        }
                    }

                    setLoading(false);

                    // awaits both promises
                    const [skipVideo, deviceCompatible] = await Promise.all([
                        skipVideoPromise,
                        deviceCompatiblePromise,
                    ]);

                    if (configId) {
                        const videoShownPreviously = !getDisplayLandingVideo(configId);
                        // Only show the landing page if
                        // the device is compatible
                        // the video hasn't been shown previously
                        // the video should be skipped (as video isn't shown when hotlinks are shown)
                        // there is a landing screen video to show
                        setShowLandingPage(
                            deviceCompatible &&
                                !videoShownPreviously &&
                                !skipVideo &&
                                conf.stylesConfig.landingScreenVideoUrl !== null
                        );
                    }
                }
                // handles common error of incorrect url
                else if (response.res?.status === 204) {
                    setLoading(false);
                } else {
                    setConfigError(true);
                    setLoading(false);
                }
            });
        }
    }, []);

    // shows nothing when config is loading, doesn't show loading screen in case client wants loading screen overridden
    if (loading) {
        return null;
    } else if (!config && !loading) {
        console.log(config);
        return (
            <FullScreenError
                title="The page cannot be found"
                msg="Please make sure you have the correct url and try again"
            />
        );
    }
    // TODO: add error page
    else if (configError || !config) {
        return <FullScreenError title="Error" msg="An error occurred processing the config" />;
    }

    return (
        <div className="App">
            <StyleOverrideLoader styleUrl={config.stylesConfig.overrideStyles} />

            {config.customForm && showCustomForm ? (
                <CustomFormPage
                    clientStub={config.clientStub}
                    formConfig={config.customForm}
                    onClose={() => {
                        setShowCustomForm(false);
                    }}
                    screenshotKey={null}
                    currentModelName={null}
                    currentMaterialNames={null}
                />
            ) : (
                <Suspense
                    fallback={
                        <LoadingScreen
                            configs={
                                config.stylesConfig.loadingScreenConfig ? config.stylesConfig.loadingScreenConfig : null
                            }
                        />
                    }
                >
                    {showLandingPage === null && (
                        <LoadingScreen
                            configs={
                                config.stylesConfig.loadingScreenConfig ? config.stylesConfig.loadingScreenConfig : null
                            }
                        />
                    )}
                    {showLandingPage === true && (
                        <LandingPage
                            styleConfigs={config.stylesConfig}
                            onClose={() => {
                                setShowLandingPage(false);
                                setDisplayLandingVideo(config.clientStub);
                            }}
                        />
                    )}
                    {showLandingPage === false && <DisplayController config={config} />}
                </Suspense>
            )}
        </div>
    );
}

export default App;
