const iosBitmapFix = () => {
    // bitmaps cause texture issues on iOS this workaround prevents black textures and crashes
    const IS_IOS =
        /^(iPad|iPhone|iPod)/.test(window.navigator.platform) ||
        (/^Mac/.test(window.navigator.platform) && window.navigator.maxTouchPoints > 1);
    if (IS_IOS) {
        //@ts-ignore
        window.createImageBitmap = undefined;
    }
};
export { iosBitmapFix };
