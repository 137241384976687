const sendToNetlifyFunction = (functionName: string, data: {}) => {
    const functionsUrl = process.env.REACT_APP_NETLIFY_FUNCTIONS_URL;
    const submissionUrl = `${functionsUrl}/${functionName}`;

    fetch(submissionUrl, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((json) => console.log(json));
};

export { sendToNetlifyFunction };
