import { IDBPDatabase, openDB } from 'idb';

// Get reference to database, and init object store(s)
const getIndexedDb = async (database: string): Promise<IDBPDatabase | null> => {
    try {
        const db = await openDB(database, 1, {
            upgrade(db) {
                const objectStore = db.createObjectStore('screenshots', { autoIncrement: true, keyPath: 'id' });
                objectStore.createIndex('date', 'date', { unique: false });
            },
        });
        return db;
    } catch (error) {
        return null;
    }
};
export { getIndexedDb };
