import type aframe from 'aframe';
import { CUSTOM_EVENTS } from '../enums/custom-events';
import { PLACEMENT_SURFACE_TYPE } from '../enums/placement-surface-type';
declare let THREE: any;

interface ICursorComponent extends Partial<aframe.Component> {
    // custom properties added specificity for this component
    rayOrigin?: THREE.Vector2;
    raycaster?: THREE.Raycaster;
    threeCamera?: THREE.Camera;
    ground?: aframe.Entity;
    wall?: aframe.Entity;
    cursorLocation?: THREE.Vector3;
    camera?: aframe.Entity;

    // functions
    onWallPlaced: any;
}

// used to add movement functionality to the cursor
const CursorComponent: ICursorComponent = {
    schema: {
        // valid values are 'Floor', 'Wall' and 'Ceiling' from PLACEMENT_SURFACE_TYPE
        placementSurfaceType: { default: 'Floor', type: 'string' },
    },

    // default init method used by Aframe components
    init() {
        this.onWallPlaced = this.onWallPlaced.bind(this);

        // 2D coordinates of the raycast origin, in normalized device coordinates (NDC)---X and Y
        // components should be between -1 and 1.  Here we want the cursor in the center of the screen.
        this.rayOrigin = new THREE.Vector2(0, 0);

        this.raycaster = new THREE.Raycaster();
        this.camera = document.getElementById('camera') as aframe.Entity;
        if (this.camera) {
            this.threeCamera = this.camera.getObject3D('camera') as THREE.Camera;
        }

        this.ground = document.getElementById('ground') as aframe.Entity;
        this.wall = document.getElementById('wall') as aframe.Entity;
        if (!this.wall) {
            const scene = document.querySelector('a-scene');
            if (scene) {
                scene.addEventListener(CUSTOM_EVENTS.WALL_PLACED, this.onWallPlaced);
            }
        }

        this.cursorLocation = new THREE.Vector3(0, 0, 0);
    },
    // default tick method used by Aframe components
    tick() {
        if (this.raycaster && this.rayOrigin && this.threeCamera && this.el && this.cursorLocation) {
            this.raycaster.setFromCamera(this.rayOrigin, this.threeCamera);

            // Raycast from camera to 'ground' for Floor or Ceiling placement modes
            if (this.data.placementSurfaceType !== PLACEMENT_SURFACE_TYPE.WALL && this.ground) {
                const intersects = this.raycaster.intersectObject(this.ground.object3D, true);

                if (intersects.length > 0) {
                    const [intersect] = intersects;
                    this.cursorLocation = intersect.point;

                    // sets facing direction
                    if (intersect.face?.normal) {
                        const targetPoint = this.el.object3D.position.clone().add(intersect.face.normal);
                        this.el.object3D.lookAt(targetPoint);
                    }

                    // sets position
                    // if the previous position was NaN just set the location instead of gradually moving there
                    if (isNaN(this.el.object3D.position.x)) {
                        this.el.object3D.position.copy(this.cursorLocation);
                    } else {
                        this.el.object3D.position.lerp(this.cursorLocation, 0.4);
                    }

                    // sets rotation
                    if (this.threeCamera.parent) {
                        this.el.object3D.rotation.y = this.threeCamera.parent.rotation.y;
                    }
                } else {
                    // no intersections, sets position of cursor to NaN so it's not shown to the user
                    this.el.object3D.position.set(NaN, NaN, NaN);
                }
            }
            // Raycast from camera to 'wall'
            else if (this.data.placementSurfaceType === PLACEMENT_SURFACE_TYPE.WALL && this.wall) {
                const intersects = this.raycaster.intersectObject(this.wall.object3D, true);

                if (intersects.length > 0) {
                    const [intersect] = intersects;
                    this.cursorLocation = intersect.point;

                    if (intersect.face) {
                        this.cursorLocation.x -= intersect.face?.normal.x * 0.1;
                        this.cursorLocation.y -= intersect.face?.normal.y * 0.1;
                        this.cursorLocation.z -= intersect.face?.normal.z * 0.1;
                    }

                    // sets position
                    // if the previous position was NaN just set the location instead of gradually moving there
                    if (isNaN(this.el.object3D.position.x)) {
                        this.el.object3D.position.copy(this.cursorLocation);
                    } else {
                        this.el.object3D.position.lerp(this.cursorLocation, 0.4);
                    }

                    // sets the cursor rotation to the same rotation as the box
                    // for some reason the intersected object is not the cube but it's parent
                    if (intersect.object.parent?.rotation) {
                        this.el.object3D.rotation.y = intersect.object.parent?.rotation.y;
                        this.el.object3D.rotation.x = intersect.object.parent?.rotation.x;
                        this.el.object3D.rotation.z = intersect.object.parent?.rotation.z;
                    }
                }
            }
        }
    },
    remove() {
        const scene = document.querySelector('a-scene');
        if (scene) {
            scene.removeEventListener(CUSTOM_EVENTS.WALL_PLACED, this.onWallPlaced);
        }
    },
    onWallPlaced() {
        this.wall = document.getElementById('wall') as aframe.Entity;
    },
};

export { CursorComponent };
