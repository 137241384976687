import React, { ReactElement } from 'react';

interface IProps {
    id?: string;
    labelText?: string;
    checked: boolean;
    onChange?: any;
}

function Checkbox({ id, labelText, checked, onChange }: IProps): ReactElement {
    return (
        <label className="relative uppercase text-left">
            <input
                type="checkbox"
                name={id}
                id={id}
                className="rounded-lg h-6 w-6"
                checked={checked}
                onChange={onChange}
            />
            <p className="absolute top-1 left-8 text-base">{labelText}</p>
        </label>
    );
}

export default Checkbox;
