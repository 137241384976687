import { CustomMenuList, SubMenu } from '../interfaces/configs/custom-menu-list/custom-menu-list';
import { ListItem } from '../interfaces/configs/custom-menu-list/list-item';
import { SubMenuNode } from '../interfaces/sub-menu-node';
import { ReferencedSubMenu } from '../interfaces/configs/custom-menu-list/custom-menu-list';

const createCustomMenuListNode = (
    customModelList: CustomMenuList,
    referencedSubMenus: Array<ReferencedSubMenu>
): SubMenuNode => {
    const rootNode: SubMenuNode = {
        ...customModelList.startingList,
        parentNode: null,
        // items is array is empty here as it's added once the node has been processed
        items: [],
    };
    // checks to see if the submenu is referencing a submenu from the list of referenced submenus
    // if so it replaces the submenu with the referenced submenu
    if (customModelList.startingList.referenceId) {
        const newSubMenu = getReferenceSubMenu(customModelList.startingList.referenceId, referencedSubMenus);
        if (newSubMenu) customModelList.startingList = newSubMenu;
    }

    processNode(rootNode, customModelList.startingList.items, referencedSubMenus);

    return rootNode;
};

// process node is an iterative function
// it gets called by itself over until it has fully traversed the entire node tree
const processNode = (
    parentNode: SubMenuNode,
    items: Array<ListItem> | null,
    referencedSubMenus: Array<ReferencedSubMenu>
) => {
    if (items === null || items === undefined) {
        return;
    }
    const itemList: Array<ListItem> = [];
    items.forEach((item: ListItem) => {
        if (item.subMenu) {
            let newSubMenuNode: SubMenuNode = {
                parentNode: parentNode,
                referenceId: null,
                titleText: item.subMenu.titleText,
                // items is set to null here as it's added once the node has been processed
                items: [],
                itemDisplayType: item.subMenu.itemDisplayType,
            };
            let items: Array<ListItem> = item.subMenu.items;

            // checks to see if the submenu is referencing a submenu from the list of referenced submenus
            // if so it replaces the submenu with the referenced submenu
            if (item.subMenu.referenceId) {
                const newSubMenu = getReferenceSubMenu(item.subMenu.referenceId, referencedSubMenus);
                if (newSubMenu) {
                    newSubMenuNode.referenceId = newSubMenu.referenceId;
                    newSubMenuNode.titleText = newSubMenu.titleText;
                    newSubMenuNode.itemDisplayType = newSubMenu.itemDisplayType;
                    items = newSubMenu.items;
                }
            }

            processNode(newSubMenuNode, items, referencedSubMenus);
            item.subMenu = newSubMenuNode;
        }
        itemList.push(item);
    });
    parentNode.items = itemList;
};

const getReferenceSubMenu = (referenceId: string, referencedSubMenus: Array<ReferencedSubMenu>): SubMenu | null => {
    const referencedSubMenu: ReferencedSubMenu | undefined = referencedSubMenus.find((rsm) => rsm.id === referenceId);

    if (referencedSubMenu) {
        const subMenu: SubMenu = {
            referenceId: referencedSubMenu.referenceId,
            titleText: referencedSubMenu.titleText,
            items: referencedSubMenu.items,
            itemDisplayType: referencedSubMenu.itemDisplayType,
        };
        return subMenu;
    }
    return null;
};

export { createCustomMenuListNode };
