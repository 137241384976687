import { proxy, useSnapshot, subscribe } from 'valtio';

type MetadataStoreType = {
    shortlink: string | null;
    metaData: Record<string, any> | null;
};

const store = proxy<MetadataStoreType>({
    shortlink: null,
    metaData: null,
});

const setShortlink = (shortlink: string) => {
    store.shortlink = shortlink;
};

const setMetaData = (metaData: Record<string, any>) => {
    store.metaData = metaData;
};

export { store, useSnapshot, subscribe, setMetaData, setShortlink };
