import React, { ReactElement } from 'react';
import './select.scss';

interface IProps {
    id?: string;
    placeholder?: string;
    optionValues?: Array<string>;
    value?: any;
    onChange?: any;
}

function Select({ id, placeholder, optionValues, value, onChange }: IProps): ReactElement {
    return (
        <select
            className="text-gray-500 rounded-lg text-base"
            title={placeholder}
            name={id}
            id={id}
            value={value}
            onChange={onChange}
        >
            <option value={placeholder}>{placeholder}</option>
            {optionValues?.map((value) => {
                return (
                    <option key={value} value={value}>
                        {value}
                    </option>
                );
            })}
        </select>
    );
}

export default Select;
