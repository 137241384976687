import type aframe from 'aframe';

interface IXRLightSystem extends Partial<aframe.System> {
    // custom properties and functions added specificity for this component
    intensity?: number;
}

const XRLightSystem: IXRLightSystem = {
    init() {
        this.intensity = 1;
        const startListen = () => {
            // @ts-ignore
            window.XR8.XrController.configure({ enableLighting: true });
            // @ts-ignore
            window.XR8.addCameraPipelineModule({
                name: 'xr-light',
                onUpdate: ({ processCpuResult }: { processCpuResult: any }) => {
                    if (
                        processCpuResult.reality &&
                        processCpuResult.reality.lighting &&
                        processCpuResult.reality.lighting.exposure
                    ) {
                        this.intensity = 1 + processCpuResult.reality.lighting.exposure;
                    }
                },
            });
        };
        // @ts-ignore
        window.XR8 ? startListen() : window.addEventListener('xrloaded', startListen, { once: true, passive: true });
    },
};

interface IXRLightComponent extends Partial<aframe.Component> {
    // custom properties and functions added specificity for this component
    // the initial intensity value of the light
    baseIntensity: number;
}

// based on code from https://www.8thwall.com/8thwall/cubemap-aframe/master/xrlight.js
// adds dynamic lighting to model by changing the intensity of lights based on camera exposure
const XRLightComponent: IXRLightComponent = {
    schema: {
        minMulti: { default: 0 },
        maxMulti: { default: 2 },
    },
    baseIntensity: 1,
    init() {
        if (this.el) {
            const light = this.el.getAttribute('light');

            if (light && light.intensity) {
                this.baseIntensity = light.intensity;
            } else {
                // makes sure the intensity is a number
                const intensity = this.el.getAttribute('intensity');
                if (!isNaN(intensity)) {
                    const intensityNum = parseFloat(intensity);
                    this.baseIntensity = intensityNum;
                }
            }
        }
    },
    tick() {
        const system: IXRLightSystem = this.system as IXRLightSystem;

        if (this.el && system && system.intensity) {
            this.el.setAttribute(
                'light',
                `intensity: ${Math.max(
                    this.data.minMulti * this.baseIntensity,
                    Math.min(system.intensity * this.baseIntensity, this.data.maxMulti * this.baseIntensity)
                )}`
            );
        }
    },
};
export { XRLightComponent, XRLightSystem };
