export enum FORM_ITEM_TYPE {
    INPUT = 'Input',
    IMAGE = 'Image',
    TEXT = 'Text',
    LINK = 'Link',
    TEXTBOX = 'Textbox',
    CHECKBOX = 'Checkbox',
    SUBMIT_BUTTON = 'SubmitButton',
    SKIP_BUTTON = 'SkipButton',
    SELECT = 'Select',
    SUBMIT_SCREENSHOT = 'SubmitScreenshot',
}
