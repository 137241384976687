import { ConfigResponse } from '../interfaces/config-response';

// retrieves the config from the api
const getConfigByClientStub = async (clientStub: string): Promise<ConfigResponse> => {
    const api_url = process.env.REACT_APP_API_URL;

    const configRes: ConfigResponse = {
        json: null,
        errorMsg: null,
        res: null,
    };

    if (api_url) {
        const res: Response | null = await fetch(`${api_url}/${clientStub}`);
        if (res.ok && res.status !== 204) {
            const json = await res.json();
            configRes.json = json;
            configRes.res = res;
        } else if (res.status !== 204) {
            configRes.errorMsg = 'config not found for id';
            configRes.res = res;
        } else {
            console.error('error fetching config', res);
            configRes.errorMsg = 'unknown error occurred';
            configRes.res = res;
        }
    } else {
        configRes.errorMsg = `api_url is not set it's current value is ${api_url}`;
    }
    return configRes;
};

export { getConfigByClientStub };
