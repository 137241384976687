import type aframe from 'aframe';
import { ACTIONS } from 'react-joyride';
import { HelpTourEvent } from '../components/help-tour/help-tour';
import { CUSTOM_EVENTS } from '../enums/custom-events';
import { PLACEMENT_SURFACE_TYPE } from '../enums/placement-surface-type';
declare let THREE: any;

interface ICustomPlaceWallComponent extends Partial<aframe.Component> {
    // custom properties added specificity for this component

    raycaster?: THREE.Raycaster;
    camera?: aframe.Entity;
    threeCamera?: THREE.Camera;
    ground?: aframe.Entity;
    scene?: aframe.Scene;
    wall?: aframe.Entity | null;

    // custom functions
    placeWall: () => void;
    handleHelpTourEvent: (e: Event) => void;
}

// based off code from here https://www.8thwall.com/8thwall/place-vertical-surface-aframe/master/wall-from-floor.js
// used to allow user to place invisible cube model to be used as a wall
const CustomPlaceWallComponent: ICustomPlaceWallComponent = {
    schema: {
        placed: { default: false, type: 'boolean' },
        // valid values are 'Floor', 'Wall' and 'Ceiling' from PLACEMENT_SURFACE_TYPE
        placementSurfaceType: { default: 'Floor', type: 'string' },
    },

    init() {
        this.raycaster = new THREE.Raycaster();
        this.camera = document.getElementById('camera') as aframe.Entity;
        this.threeCamera = this.camera.getObject3D('camera') as THREE.Camera;
        this.ground = document.getElementById('ground') as aframe.Entity;
        this.scene = this.el?.sceneEl as aframe.Scene;
        this.placeWall = this.placeWall.bind(this);
        this.handleHelpTourEvent = this.handleHelpTourEvent.bind(this);

        // click event handles the wall placement
        this.scene.addEventListener('click', this.placeWall);
        document.addEventListener(CUSTOM_EVENTS.HELP_TOUR_EVENT, this.handleHelpTourEvent);
    },
    tick() {
        if (!this.data.placed) {
            if (this.el && this.camera && this.ground && this.raycaster) {
                let pos = new THREE.Vector3(0, 0, 0);
                const a = new THREE.Vector2(0, -0.25);

                if (!this.threeCamera) {
                    this.threeCamera = this.threeCamera || (this.camera.getObject3D('camera') as THREE.Camera);
                }

                this.raycaster.setFromCamera(a, this.threeCamera);
                const intersects = this.raycaster.intersectObject(this.ground.object3D, true);
                if (intersects.length > 0) {
                    pos = intersects[0].point;
                }
                this.el.object3D.position.lerp(pos, 0.4);
                this.el.object3D.rotation.y = this.camera.object3D.rotation.y;
            }
        }
    },
    update() {
        if (this.data.placed === false) {
            if (this.wall) {
                this.wall.remove();
                this.wall = null;
                if (this.el) {
                    this.el.object3D.visible = true;
                }
            }
        }
    },
    remove() {
        if (this.scene) {
            this.scene.removeEventListener('click', this.placeWall);
        }
    },
    handleHelpTourEvent(e: Event) {
        const customEvent: CustomEvent = e as CustomEvent;
        const eventData: HelpTourEvent = customEvent.detail;
        // if the next step is with the wall displayed and the step is moving forward (moving from step without wall) then place wall
        if (
            eventData.nextTarget === '.help-tour-target-place-model-wall-2' &&
            eventData.data?.action === ACTIONS.NEXT
        ) {
            this.placeWall();
        }
    },
    placeWall() {
        // as it's not the correct placement mode don't place wall
        if (this.data.placementSurfaceType !== PLACEMENT_SURFACE_TYPE.WALL) return;

        if (this.el && this.data.placed === false && this.scene) {
            // has to be done like this or the update doesn't register correctly
            this.el.setAttribute('custom-place-wall-component', 'placed', true);
            let wall = document.createElement('a-box');

            wall.id = 'wall';
            wall.className = 'cantap';
            wall.setAttribute('material', { color: 'white', transparent: true, opacity: 0 });
            wall.object3D.scale.set(100, 100, 0.25);
            wall.object3D.rotation.y = this.el.object3D.rotation.y;
            wall.setAttribute('position', {
                x: this.el.object3D.position.x,
                y: this.el.object3D.position.y,
                z: this.el.object3D.position.z,
            });

            this.wall = wall as aframe.Entity;

            this.scene.appendChild(wall);
            // hides line as wall has now been placed
            this.el.object3D.visible = false;
            this.el.emit(CUSTOM_EVENTS.WALL_PLACED);
        }
    },
};

export { CustomPlaceWallComponent };
