// config overrides that can be passed in as url parameters
export enum URL_CONFIG_OVERRIDES {
    // allows user to set which model is the initial model by id, if there isn't a model with this material id nothing is done
    MODEL_ID = 'modid',
    // allows user to set only a subset of displayed models by using an array of model indices, disables the custom model list
    // e.g. ['table', 'chair', 'car']
    AVAILABLE_MODELS_IDS = 'amids',
    // allows user to set how the AVAILABLE_MODELS_IDS are displayed has 3 options
    // ONLY_DISPLAY_MODELS which is the default option that we are currently using. It causes all the models to display in a flat list
    // HIDE_MODELS_IN_CUSTOM_MENU hides the models in the array from the menu and any item that has a submenu without any items in it (as they have now been hidden)
    // SHOW_MODELS_IN_CUSTOM_MENU hides all models not in the array from the menu and any item that has a submenu without any items in it (as they have now been hidden)
    AVAILABLE_MODEL_DISPLAY_TYPE = 'amdt',
    // which materials are available on the model, should be an array with each element referring to a material slot on the model
    // e.g. ['blue tiles', 'white pebbles']
    MATERIAL_IDS = 'matids',
    // Used for URL shortening. Config stored in DB
    HOTLINK_ID = 'l',
    // Used for Warwick Website (Same functionality as hotlink)
    WARWICK_HOTLINK_ID = 'wfc',
    // Used for Warwick Website (Same functionality as hotlink)
    BED_THREADS_HOTLINK_ID = 'bt',
    // used to set the selected sub models if any exist
    SUB_MODEL_IDS = 'smids',
    // initial scale of the first set model
    INITIAL_SCALE = 'is',
    // allows the use of custom textures applied to materials
    CUSTOM_TEXTURE_URLS = 'ctu',
    // allows the url for custom buttons to be overridden
    CUSTOM_LINK_URL = 'clu',
    // the string based id of the loading screen to use
    LOADING_SCREEN = 'ls',
    // used to hide and show parts of the model
    VISIBILITY_STATE = 'vs',
}
