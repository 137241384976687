import { useState } from 'react';
import { CustomFormConfig } from '../../interfaces/configs/custom-form-configs/custom-form-config';
import './custom-form-page.scss';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { setCustomFormAsCompleted } from '../../helpers/custom-form-completed';
import { FORM_SCREENSHOT_KEY, MODEL_NAME_KEY, MODEL_MATERIALS_KEY } from '../../enums/form-unique-keys';
import { sendAnalytics } from '../../helpers/analytics/send-analytics';
import { FormSubmissionConfirmation } from '../form-submission-confirmation/form-submission-confirmation';
import CustomForm from '../custom-form/custom-form';
import { sendToNetlifyFunction } from '../../helpers/custom-form-actions/send-to-netlify-function';
import { getIndexedDb } from '../../helpers/get-indexed-db';
import { ScreenshotData } from '../../interfaces/screenshot-data';

interface FormElementData {
    value: boolean | string | number;
    changed: boolean;
    required: boolean;
    requiredTrue?: boolean;
}

interface IProps {
    clientStub: string;
    formConfig: CustomFormConfig;
    onClose: Function;
    screenshotKey: number | null;
    currentModelName: string | null;
    currentMaterialNames: Array<string> | null;
}
function CustomFormPage(props: IProps) {
    const { clientStub, formConfig, onClose, screenshotKey, currentModelName, currentMaterialNames } = props;

    const [showSendingWheel, setShowSendingWheel] = useState<boolean>(false);
    const [showConfirmationPanel, setShowConfirmationPanel] = useState<boolean>(false);

    const onSubmit = async (formState: any) => {
        setShowSendingWheel(true);
        const api_url = process.env.REACT_APP_API_URL;

        if (formConfig.analyticsTrackingCode) {
            sendAnalytics(formConfig.analyticsTrackingCode, 'pageload');
        }

        let data: any = {};
        for (const [k, v] of Object.entries(formState)) {
            const val: FormElementData = v as FormElementData;

            // custom handling for uploading screenshot
            if (k === FORM_SCREENSHOT_KEY) {
                if (val.value === false) {
                    data[FORM_SCREENSHOT_KEY] = null;
                } else {
                    const db = await getIndexedDb(clientStub);
                    if (db && screenshotKey) {
                        const screenshot : ScreenshotData = await db.get('screenshots', screenshotKey);
                        if (screenshot) {
                            data[FORM_SCREENSHOT_KEY] = screenshot.data;
                        }
                    }
                }
            } else {
                data[k] = val.value.toString();
            }
        }
        // adds the model name to the data
        if (formConfig.sendCurrentModelName && currentModelName) {
            data[MODEL_NAME_KEY] = currentModelName.toString();
            data[MODEL_MATERIALS_KEY] = '[' + (currentMaterialNames ? currentMaterialNames : []).toString() + ']';
        }

        fetch(`${api_url}/${clientStub}/${formConfig.submitUrl}`, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                clientStub: clientStub,
                eventDate: new Date().toISOString(),
                EventData: data,
            }),
        }).then((response) => {
            console.log(response);

            // Execute custom functions on form submission, based on client config
            if (formConfig.customFormSubmissionFunction) {
                const functionName = formConfig.customFormSubmissionFunctionName || 'noop';
                sendToNetlifyFunction(functionName, data);
            }

            setShowSendingWheel(false);

            if (formConfig.hideConfirmationPopup) {
                closeFormPage();
            } else setShowConfirmationPanel(true);
        });
    };

    const closeFormPage = () => {
        setCustomFormAsCompleted(formConfig.localStorageKey);
        onClose();
    };

    const textColor = formConfig.textColor || '#ffffff';
    const backgroundColor = formConfig.backgroundColor || '#ffffff';
    const inputTextColor = formConfig.inputTextColor || '#000000';
    const inputBackgroundColor = formConfig.inputBackgroundColor || '#ffffff';
    const submitButtonColor = formConfig.submitButtonColor || '#838383';
    const submitButtonTextColor = formConfig.submitButtonTextColor || '#ffffff';

    const useStyles = makeStyles({
        root: {
            backgroundColor: backgroundColor,
            minHeight: '100%',
        },
        headline: {
            color: textColor,
            fontSize: '1rem',
            textTransform: 'uppercase',
        },
        subtitle: {
            background: 'green',
            fontSize: '1rem',
            padding: '2rem',
        },
        formInputs: {
            '& input': {
                backgroundColor: inputBackgroundColor,
                '&::placeholder': {
                    color: inputTextColor,
                },
            },
            '& p': {
                color: textColor,
            },
            '& select': {
                backgroundColor: inputBackgroundColor,
                color: inputTextColor,
            },
        },
        submitButton: {
            backgroundColor: submitButtonColor,
            color: submitButtonTextColor,
        },
        disabled: {
            opacity: 0.5,
        },
        cancelButton: {
            backgroundColor: submitButtonTextColor,
            color: submitButtonColor,
            borderColor: submitButtonColor,
            borderWidth: '1px',
            borderStyle: 'solid',
        },
    });

    const classes = useStyles();

    return (
        <div
            className="custom-form-page"
            style={{
                ...(formConfig.backgroundColor ? { backgroundColor: formConfig.backgroundColor } : {}),
            }}
        >
            {showConfirmationPanel && (
                <FormSubmissionConfirmation
                    formConfig={formConfig}
                    onClose={() => {
                        closeFormPage();
                    }}
                />
            )}
            <CustomForm
                clientStub={clientStub}
                formConfig={formConfig}
                onCancel={closeFormPage}
                onSubmit={onSubmit}
                customStyles={classes}
            />
            {showSendingWheel && (
                <div className="sending-background">
                    <CircularProgress
                        size="8em"
                        style={{
                            color: formConfig.inputBackgroundColor || 'white',
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export { CustomFormPage };
