const addIosPopupCustomText = (text: string, cancelButtonText: string, continueButtonText: string): void => {
    let inDom = false;
    const observer = new MutationObserver(() => {
        if (document.querySelector('.prompt-box-8w')) {
            if (!inDom) {
                document.querySelector('.prompt-box-8w p').innerHTML = text;
                document.querySelector('.prompt-button-8w').innerHTML = cancelButtonText;
                document.querySelector('.button-primary-8w').innerHTML = continueButtonText;
            }
            inDom = true;
        } else if (inDom) {
            inDom = false;
            observer.disconnect();
        }
    });
    observer.observe(document.body, { childList: true });
};

export { addIosPopupCustomText };
