import React from 'react';

interface IProps {
    styleUrl: string | null;
}
function StyleOverrideLoader(props: IProps) {
    const { styleUrl } = props;

    if (styleUrl === null) return null;

    return (
        <>
            <link rel="stylesheet" type="text/css" href={styleUrl} />
        </>
    );
}

export { StyleOverrideLoader };
