import { AVAILABLE_MODEL_DISPLAY_TYPE } from '../enums/available-model-display-type';
import { MENU_EFFECT_TYPE } from '../enums/menu-effect-type';
import { URL_CONFIG_OVERRIDES } from '../enums/url-config-overrides';
import { AppConfig } from '../interfaces/configs/app-config';
import { CustomLinkUrlOverride } from '../interfaces/configs/custom-link-url-override';
import { ListItem } from '../interfaces/configs/custom-menu-list/list-item';
import { MenuEffect, VisibilityChange } from '../interfaces/configs/custom-menu-list/menu-effect';
import { MaterialTextureCombination } from '../interfaces/configs/material-texture-combination';
import { ModelData } from '../interfaces/configs/model-data-configs/model-data';
import { setMetaData, setShortlink } from '../store/metadata-store';
import { setUrl } from '../store/url-store';

const applyHotlinkOverrides = async (config: AppConfig): Promise<boolean> => {
    // Should we skip the landing video?
    let skipVideo = false;

    // Get Hotlink API url
    const apiUrl = process.env.REACT_APP_API_HOTLINK_URL;

    // gets params from url
    const params = new URLSearchParams(window.location.search);

    // const modelIdStr: string | null = params.get(URL_CONFIG_OVERRIDES.MODEL_ID);
    // const availableModelsIdsStr: string | null = params.get(URL_CONFIG_OVERRIDES.AVAILABLE_MODELS_IDS);
    const warwickHotlinkId: string | null = params.get(URL_CONFIG_OVERRIDES.WARWICK_HOTLINK_ID);

    const bedThreadsHotlinkId: string | null = params.get(URL_CONFIG_OVERRIDES.BED_THREADS_HOTLINK_ID);

    let hotlinkId: string | null = params.get(URL_CONFIG_OVERRIDES.HOTLINK_ID);

    if (warwickHotlinkId) {
        hotlinkId = warwickHotlinkId;
    }
    if (bedThreadsHotlinkId) {
        hotlinkId = bedThreadsHotlinkId;
    }

    if (apiUrl && hotlinkId) {
        const res: Response | null = await fetch(`${apiUrl}/${hotlinkId}`);
        if (res.ok && res.status !== 204) {
            const json = await res.json();
            //TODO: We should type this
            let {
                modelId,
                availableModelsIds,
                availableModelDisplayType,
                materialIds,
                subModelIds,
                initialScale,
                customTextureUrls,
                customLinkUrlOverride,
                additionalData,
            } = json;

            let initialVisibilityChange = null;

            if (bedThreadsHotlinkId) {
                console.log('bed threads hotlink id', bedThreadsHotlinkId);
                const flatSheetModelNames = ['flat-sheet_1'];
                const scallopedEuropeanPillowsModelNames = ['scalloped-euro-inner_1_1', 'scalloped-euro-outer_1_1'];
                const scalStandPillowsForEuroPillows = ['scalloped-standard-inner_1_1', 'scalloped-standard-outer_1_1'];
                const scalStandPillowsForSecondPillows = [
                    'scalloped-standard-inner_2_1',
                    'scalloped-standard-outer_2_1',
                ];
                const scallopedSecondPillowsModelNames = ['scalloped-second-inner_1_1', 'scalloped-second-outer_1_1'];

                // the following have 2 values as each has there own version of the standard pillows
                const secondPillowsModelNames = ['standard-pillowcase_2', 'second-pillowcase_1'];

                const europeanPillowsModelNames = ['standard-pillowcase_1', 'european-pillowcase-scalloped_1'];

                // set to an array so it's easy to push values into it, without checking if it's null
                initialVisibilityChange = [];

                if (Array.isArray(materialIds)) {
                    // if the flat sheet is included
                    if (materialIds.length >= 3) {
                        if (materialIds[2] === 'none') {
                            // hide the flat sheet
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(flatSheetModelNames, false)
                            );
                        } else {
                            // show the flat sheet
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(flatSheetModelNames, true)
                            );
                        }
                    }

                    // handles the scalloped european pillows
                    if (materialIds.length >= 6) {
                        if (materialIds[5] === 'none') {
                            // hide the scalloped pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(scallopedEuropeanPillowsModelNames, false)
                            );
                        } else {
                            // show the scalloped pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(scallopedEuropeanPillowsModelNames, true)
                            );
                        }
                        // TODO: THIS IS A TEMPORARY FIX TO HANDLE THE SECOND STANDARD SCALLOPED PILLOWS
                        // IT NEEDS TO BE REMOVED ONCE THESE GO LIVE
                        if (materialIds.length === 7) {
                            const secondScallopedPillows = [
                                'scalloped-standard-inner_1_1',
                                'scalloped-standard-outer_1_1',
                            ];
                            // Hide the Second Standard Scalloped Pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(secondScallopedPillows, false)
                            );
                        }
                    }

                    // handles the second pillows
                    if (materialIds.length >= 7) {
                        if (materialIds[6] === 'none') {
                            // hide the scalloped pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(secondPillowsModelNames, false),
                                ...createVisibilityChangeArrayFromNames(europeanPillowsModelNames, true)
                            );
                        } else {
                            // show the scalloped pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(secondPillowsModelNames, true),
                                ...createVisibilityChangeArrayFromNames(europeanPillowsModelNames, false)
                            );
                        }
                    }
                    // handles the scalloped standard pillows
                    if (materialIds.length >= 8) {
                        if (materialIds[7] === 'none') {
                            // hide the scalloped pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(scalStandPillowsForEuroPillows, false),
                                ...createVisibilityChangeArrayFromNames(scalStandPillowsForSecondPillows, false)
                            );
                        } else {
                            // show the scalloped pillows
                            // there are 2 versions of the scalloping one for the second pillows and one for the euro pillows

                            // if there aren't any second pillows, hide the second pillow version and show the euro pillow version
                            if (materialIds[6] === 'none') {
                                initialVisibilityChange.push(
                                    ...createVisibilityChangeArrayFromNames(scalStandPillowsForEuroPillows, true),
                                    ...createVisibilityChangeArrayFromNames(scalStandPillowsForSecondPillows, false)
                                );
                            } else {
                                initialVisibilityChange.push(
                                    ...createVisibilityChangeArrayFromNames(scalStandPillowsForEuroPillows, false),
                                    ...createVisibilityChangeArrayFromNames(scalStandPillowsForSecondPillows, true)
                                );
                            }
                        }
                    }
                    // handles the scalloped second pillows
                    if (materialIds.length >= 9) {
                        if (materialIds[8] === 'none') {
                            // hide the scalloped pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(scallopedSecondPillowsModelNames, false)
                            );
                        } else {
                            // show the scalloped pillows
                            initialVisibilityChange.push(
                                ...createVisibilityChangeArrayFromNames(scallopedSecondPillowsModelNames, true)
                            );
                        }
                    }
                }
                // if the array is still empty set it back to null
                // @ts-ignore
                if (initialVisibilityChange === []) {
                    initialVisibilityChange = null;
                }

                // Parse incoming additional data
                if (additionalData) {
                    // Addition data is stored in "items" array
                    const { items, returnUrl } = additionalData;

                    if (items) {
                        // Items is stringified JSON, so parse it
                        const parsedItems = JSON.parse(items);

                        // Convert to appropriate format
                        additionalData.items = parsedItems;
                        setMetaData(additionalData);
                        setShortlink(bedThreadsHotlinkId);
                    }

                    if (returnUrl) {
                        setUrl(returnUrl);
                    }
                }
            }

            if ((modelId && availableModelsIds?.length < 2) || (modelId && !availableModelsIds)) {
                skipVideo = true;
            }

            applyModelAvailableModelsIds(availableModelsIds, availableModelDisplayType, config);
            applyInitialModelId(modelId, config);
            applyInitialSubModelId(subModelIds, config);
            applyInitialModelScale(initialScale, config);
            applyInitialVisibilityStateOverrides(initialVisibilityChange, config);
            applyMaterialOverrides(materialIds, config);
            applyCustomTextureUrlOverrides(customTextureUrls, config);
            applyCustomLinkUrlOverride(customLinkUrlOverride, config);
        }
    }
    // if hotlink isn't provided, normal url overrides are used
    else {
        const modelId = params.get(URL_CONFIG_OVERRIDES.MODEL_ID) as any | null;
        const availableModelsIdsString = params.get(URL_CONFIG_OVERRIDES.AVAILABLE_MODELS_IDS) as any | null;
        const availableModelDisplayTypeString = params.get(URL_CONFIG_OVERRIDES.AVAILABLE_MODEL_DISPLAY_TYPE) as
            | any
            | null;
        const materialIdsString = params.get(URL_CONFIG_OVERRIDES.MATERIAL_IDS) as any | null;
        const subModelIdsString = params.get(URL_CONFIG_OVERRIDES.SUB_MODEL_IDS) as any | null;
        const initialScaleString = params.get(URL_CONFIG_OVERRIDES.INITIAL_SCALE) as any | null;
        const initialVisibilityChangeString = params.get(URL_CONFIG_OVERRIDES.VISIBILITY_STATE) as any | null;
        const customTextureUrlsString = params.get(URL_CONFIG_OVERRIDES.CUSTOM_TEXTURE_URLS) as any | null;
        const customLinkUrlString = params.get(URL_CONFIG_OVERRIDES.CUSTOM_LINK_URL) as any | null;

        let availableModelsIds = null;
        let availableModelDisplayType = null;
        let materialIds = null;
        let subModelIds = null;
        let initialScale = null;
        let initialVisibilityChange = null;
        let customTextureUrls = null;
        let customLinkUrl = null;

        // try catch is only used to prevent errors
        try {
            availableModelsIds = JSON.parse(availableModelsIdsString);
        } catch (e) {
            console.error(e);
        }
        try {
            availableModelDisplayType = JSON.parse(availableModelDisplayTypeString);
        } catch (e) {
            console.error(e);
        }
        try {
            materialIds = JSON.parse(materialIdsString);
        } catch (e) {
            console.error(e);
        }
        try {
            subModelIds = JSON.parse(subModelIdsString);
        } catch (e) {
            console.error(e);
        }
        try {
            initialScale = JSON.parse(initialScaleString);
        } catch (e) {
            console.error(e);
        }
        try {
            initialVisibilityChange = JSON.parse(initialVisibilityChangeString);
        } catch (e) {
            console.error(e);
        }
        try {
            customTextureUrls = JSON.parse(customTextureUrlsString);
        } catch (e) {
            console.error(e);
        }
        try {
            customLinkUrl = JSON.parse(customLinkUrlString);
        } catch (e) {
            console.error(e);
        }

        if ((modelId && availableModelsIds?.length < 2) || (modelId && !availableModelsIds)) {
            skipVideo = true;
        }

        applyModelAvailableModelsIds(availableModelsIds, availableModelDisplayType, config);
        applyInitialModelId(modelId, config);
        applyInitialSubModelId(subModelIds, config);
        applyInitialModelScale(initialScale, config);
        applyInitialVisibilityStateOverrides(initialVisibilityChange, config);
        applyMaterialOverrides(materialIds, config);
        applyCustomTextureUrlOverrides(customTextureUrls, config);
        applyCustomLinkUrlOverride(customLinkUrl, config);
    }

    return skipVideo;
};

const applyModelAvailableModelsIds = (
    ids: Array<string> | null,
    displayType: AVAILABLE_MODEL_DISPLAY_TYPE | null,
    config: AppConfig
) => {
    if (displayType === AVAILABLE_MODEL_DISPLAY_TYPE.ONLY_DISPLAY_MODELS || !displayType) {
        // if parsed json was non empty array continue
        if (Array.isArray(ids) && ids.length > 0) {
            let newModels: Array<ModelData> = [];
            // gets the correct new models and puts them in array
            ids.forEach((id) => {
                const targetModel: ModelData | undefined = config.modelData.find((md: ModelData) => md.id === id);
                if (targetModel) {
                    newModels.push(targetModel);
                }
            });
            config.modelData = newModels;
            // clears custom model list to make sure model isn't loaded
            config.customModelList = null;
        }
    } else if (
        [
            AVAILABLE_MODEL_DISPLAY_TYPE.SHOW_MODELS_IN_CUSTOM_MENU,
            AVAILABLE_MODEL_DISPLAY_TYPE.HIDE_MODELS_IN_CUSTOM_MENU,
        ].includes(displayType)
    ) {
        // if listed models need to be hidden instead of shown
        const invert = displayType === AVAILABLE_MODEL_DISPLAY_TYPE.HIDE_MODELS_IN_CUSTOM_MENU;
        if (config.customModelList) {
            // a separate for loop is used here as it's processing the submenu directly not the item like the processItem function
            for (let i = 0; i < config.customModelList.startingList.items.length; i++) {
                const item: ListItem = config.customModelList.startingList.items[i];
                const matchFound = processItem(item, ids, invert);
                if (!matchFound) {
                    // removes the item from the array
                    config.customModelList.startingList.items.splice(i, 1);
                    // as the array is 1 item shorter i is reduced by 1 to compensate
                    i--;
                }
            }
        }
    }
};

const processItem = (item: ListItem, ids: Array<string> | null, invert: boolean): boolean => {
    // set to true when match is found and item should be shown and false when no match is found and item should be hidden
    let returnValue = false;

    // processes the item based on it's effects
    if (item.effects.length > 0 && ids) {
        item.effects.forEach((menuEffect: MenuEffect) => {
            // if the item loads one of the listed products it's set to being a match
            if (
                menuEffect.effectType === MENU_EFFECT_TYPE.CHANGE_PRODUCT &&
                menuEffect.productChangeData &&
                ids.includes(menuEffect.productChangeData.productId)
            ) {
                returnValue = invert ? false : true;
            } else returnValue = invert ? true : false;
        });
    }

    // processes the submenu of the item and calls processItem recursively
    if (item.subMenu && item.subMenu.items.length > 0) {
        const subItems = item.subMenu.items;
        for (let i = 0; i < subItems.length; i++) {
            const item: ListItem = subItems[i];
            const matchFound = processItem(item, ids, invert);
            if (!matchFound) {
                subItems.splice(i, 1);
                i--;
            }
        }
        // item.subMenu.items = subItems;
        // when there is no items sets the return value to false so the item is not shown
        if (subItems.length === 0) {
            returnValue = false;
        } else {
            returnValue = true;
        }
    }
    return returnValue;
};

const applyInitialModelId = (str: string | null, config: AppConfig) => {
    if (!str) return;

    const targetModelIndex: number | undefined = config.modelData.findIndex((md: ModelData) => md.id === str);
    if (targetModelIndex !== undefined) {
        config.initialModelIndex = targetModelIndex;
    }
};

const applyInitialSubModelId = (subModelIds: Array<string> | null, config: AppConfig) => {
    if (subModelIds !== null && Array.isArray(subModelIds)) {
        config.initialSubModelIds = subModelIds;
    }
};

const applyInitialModelScale = (scale: number | null, config: AppConfig) => {
    if (typeof scale === 'number' && scale > 0) {
        config.initialModelScale = scale;
    }
};

const applyMaterialOverrides = (materialIds: Array<string> | null, config: AppConfig) => {
    if (materialIds !== null && Array.isArray(materialIds)) {
        // doesn't provide any checks on if materials are valid on model in case the model isn't set to allow the uer to select a model
        // and them have the material load for there selected model (each of the model options must have the material)
        config.initialModelMaterialsIds = materialIds;
    }
};

const applyCustomTextureUrlOverrides = (
    customTextureUrls: Array<MaterialTextureCombination> | null,
    config: AppConfig
) => {
    if (customTextureUrls !== null && Array.isArray(customTextureUrls)) {
        config.customTextureURLOverrides = customTextureUrls;
    }
};

const applyCustomLinkUrlOverride = (customLinkURLOverride: Array<CustomLinkUrlOverride> | null, config: AppConfig) => {
    if (customLinkURLOverride !== null) {
        config.customLinkURLOverride = customLinkURLOverride;
    }
};

const createVisibilityChangeArrayFromNames = (names: Array<string>, value: boolean): Array<VisibilityChange> => {
    return names.map((name: string) => {
        return {
            modelName: name,
            visibilityState: value,
        };
    });
};

const applyInitialVisibilityStateOverrides = (visibilityChanges: Array<VisibilityChange> | null, config: AppConfig) => {
    if (visibilityChanges !== null) {
        config.initialVisibilityState = visibilityChanges;
    }
};

export { applyHotlinkOverrides };
