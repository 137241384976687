import React, { ReactElement } from 'react';

interface Props {
    onClick: any;
    label: string;
    customStyles?: string;
    disabled?: boolean;
    dynamicWidth?: boolean;
}

function Button({ onClick, label, customStyles, disabled, dynamicWidth }: Props): ReactElement {
    return (
        <button
            className={`p-2 font-bold text-base rounded-md uppercase ${
                dynamicWidth === true ? '' : 'w-32'
            } h-10 ${customStyles}`}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    );
}

export default Button;
