const customFormCompleted = (localStorageKey: string) => {
    // if '' is used as key it's always returns false, this is mostly for testing
    if (localStorageKey === '') return false;
    if (localStorage.getItem(localStorageKey) === 'true') {
        return true;
    }
    return false;
};

const setCustomFormAsCompleted = (localStorageKey: string) => {
    localStorage.setItem(localStorageKey, 'true');
};
export { customFormCompleted, setCustomFormAsCompleted };
