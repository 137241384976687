import { v4 as uuidv4 } from 'uuid';

// sends analytics to the api
const sendAnalytics = (trackingCode: string, event: 'pageload' | 'Click' | 'Model Place'): void => {
    const sendAnalytics = process.env.REACT_APP_SEND_ANALYTICS;
    const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;

    // retrieves uuid
    let uid: string | null = localStorage.getItem('considar_uid');

    // generates and saves uuid if needed
    if (!uid) {
        uid = uuidv4();
        localStorage.setItem('considar_uid', uid);
    }

    if (sendAnalytics === 'false') {
        console.warn('analytics not sent as they are disabled in the environment config', trackingCode, event, uid);
        return;
    }

    if (uid) {
        fetch(`${analyticsUrl}?cid=${trackingCode}&evt=${event}&uid=${uid}`).then((response) => {
            console.log(`${event} tracked with code ${trackingCode} and uid ${uid}`);
        });
    } else console.error(`tracking not sent as uid is ${uid}, tracking code is ${trackingCode} and event is ${event}`);
};

export { sendAnalytics };
