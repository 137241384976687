import type aframe from 'aframe';
import type three from 'three';
import { PLACEMENT_SURFACE_TYPE } from '../enums/placement-surface-type';
import { calculateAngle } from '../helpers/calculate-angle';
import { addEventListener } from '../interfaces/add-event-listener';
declare let THREE: any;

// basic data structure used to store pointer information, used for detecting touch gestures
// defined here as it's only used in this file
interface TouchData {
    position_1: {
        x: number;
        y: number;
    };
    position_2: {
        x: number;
        y: number;
    };
}

// used to allow touch rotation of model
// rotation is based on the change in angle of the line between the 2 touch points
interface ICustomTwoFingerRotateComponent extends Partial<aframe.Component> {
    // custom properties added specificity for this component
    previousTouchData?: TouchData | null;

    camera?: three.Camera | null;

    // functions
    onTouchStart: any;
    onTouchMove: any;
    onTouchEnd: any;
    updatePreviousTouchData: any;
}

const CustomTwoFingerRotateComponent: ICustomTwoFingerRotateComponent = {
    schema: {
        factor: { default: 1 },
        // valid values are 'Floor', 'Wall' and 'Ceiling' from PLACEMENT_SURFACE_TYPE
        placementSurfaceType: { default: 'Floor', type: 'string' },
    },
    init() {
        this.updatePreviousTouchData = this.updatePreviousTouchData.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.camera = document.querySelector('a-camera').object3D as three.Camera;

        // addEventListener type is used as the standard event listener in typescript doesn't support and object as the second paramter passive event listeners
        (this.el?.sceneEl?.addEventListener as addEventListener)('touchstart', this.onTouchStart, { passive: true });
        (this.el?.sceneEl?.addEventListener as addEventListener)('touchmove', this.onTouchMove, { passive: true });
        this.el?.sceneEl?.addEventListener('touchend', this.onTouchEnd);
        this.el?.classList.add('cantap'); // Needs "objects: .cantap" attribute on raycaster.
    },
    remove() {
        this.el?.sceneEl?.removeEventListener('touchstart', this.onTouchStart);
        this.el?.sceneEl?.removeEventListener('touchmove', this.onTouchMove);
        this.el?.sceneEl?.removeEventListener('touchend', this.onTouchEnd);
    },
    onTouchStart(evt: TouchEvent) {
        this.updatePreviousTouchData(evt);
    },
    onTouchMove(evt: TouchEvent) {
        // if user is touching with 2 or more fingers and they were during the previous update
        if (this.previousTouchData && evt.touches.length >= 2) {
            // calculate angle between first 2 fingers, for both the current and previous touch
            const anglePrevious = calculateAngle(
                this.previousTouchData.position_1.x,
                this.previousTouchData.position_1.y,
                this.previousTouchData.position_2.x,
                this.previousTouchData.position_2.y
            );

            const angleCurrent = calculateAngle(
                evt.touches[0].clientX,
                evt.touches[0].clientY,
                evt.touches[1].clientX,
                evt.touches[1].clientY
            );

            const rotationDelta = anglePrevious - angleCurrent;
            if (this.el) {
                switch (this.data.placementSurfaceType) {
                    case PLACEMENT_SURFACE_TYPE.FLOOR:
                        // rotates around global y axis so model rotates on floor / ground
                        this.el.object3D.rotation.y += rotationDelta * this.data.factor;
                        break;
                    case PLACEMENT_SURFACE_TYPE.WALL:
                        // rotates around local z axis so model rotates on wall

                        let factor = this.data.factor;

                        // determines if the model is facing backwards and if so multiples the factor by -1 so the model rotates teh correct direction
                        if (this.camera) {
                            // uses dot product of the camera and model to determine if the model is facing the correct direction
                            const cameraWorldDir = new THREE.Vector3(0, 0, 0);
                            const modelWorldDir = new THREE.Vector3(0, 0, 0);
                            this.camera.getWorldDirection(cameraWorldDir);
                            this.el.object3D.getWorldDirection(modelWorldDir);

                            const dotProduct: number = cameraWorldDir.dot(modelWorldDir);
                            if (dotProduct < 0) factor = factor * -1;
                        }

                        this.el.object3D.rotateOnAxis(new THREE.Vector3(0, 0, 1), rotationDelta * factor);
                        break;
                    case PLACEMENT_SURFACE_TYPE.CEILING:
                        // rotates around global y axis so model rotates on ceiling
                        // uses -= instead of += as it's in reverse as the user is looking up to the model instead of down to the model
                        this.el.object3D.rotation.y -= rotationDelta * this.data.factor;
                        break;
                    default:
                        break;
                }
            }
        }
        this.updatePreviousTouchData(evt);
    },
    onTouchEnd(evt: TouchEvent) {
        // if user is touching with 2 or more fingers and they were during the previous update
        this.updatePreviousTouchData(evt);
    },
    updatePreviousTouchData(evt: TouchEvent) {
        // if there is at least 2 touches updates the previous touch point
        if (evt.touches.length >= 2) {
            this.previousTouchData = {
                position_1: {
                    x: evt.touches[0].clientX,
                    y: evt.touches[0].clientY,
                },
                position_2: {
                    x: evt.touches[1].clientX,
                    y: evt.touches[1].clientY,
                },
            };
        }
        // clears previous touch point as there are less than 2 touches
        else {
            this.previousTouchData = null;
        }
    },
};

export { CustomTwoFingerRotateComponent };
