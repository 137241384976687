import React from 'react';
import './full-screen-error.scss';

interface IProps {
    title: string;
    msg: string;
}
function FullScreenError(props: IProps) {
    const { title, msg } = props;

    return (
        <div className="full-screen-error">
            <span className="text-heading"> {title} </span>
            <span className="text-body"> {msg} </span>
        </div>
    );
}

export { FullScreenError };
