import React, { useRef } from 'react';
import './landing-page-modal.scss';
import { StylesConfig } from '../../interfaces/configs/styles-configs/styles-config';
import { Fab } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DEFAULT_ASSETS } from '../../enums/default-assets';

interface IProps {
    styleConfigs: StylesConfig;
    onClose: Function;
}
function LandingPage(props: IProps) {
    const { styleConfigs, onClose } = props;

    const close = () => {
        onClose();
    };

    const videoRef = useRef<HTMLVideoElement | null>(null);
    // const [showVideo, setShowVideo] = useState(false);

    // useEffect(() => {
    //     const handelDataLoaded = (e: any) => {
    //         //Video should now be loaded but we can add a second check
    //         if (videoRef.current && videoRef.current.readyState >= 3) {
    //             setShowVideo(true);
    //         }
    //     }
    //     let currentVideo: HTMLVideoElement | null = null;
    //     if (videoRef && videoRef.current) {
    //         videoRef.current.addEventListener('loadeddata', handelDataLoaded);
    //         currentVideo = videoRef.current;
    //     }

    //     return () => {
    //         if (currentVideo) {
    //             currentVideo.removeEventListener('loadeddata', handelDataLoaded);
    //         }
    //     }
    // });

    return (
        <div className="landing-page-background">
            <div className="video-wrapper">
                <video
                    ref={videoRef}
                    style={{ borderColor: styleConfigs.backgroundColor }}
                    autoPlay
                    playsInline
                    muted={true}
                    controls
                    preload="auto"
                    src={styleConfigs.landingScreenVideoUrl || DEFAULT_ASSETS.LANDING_SCREEN_VIDEO_URL}
                    onEnded={close}
                />
                <Fab className="close-button">
                    <Close onClick={close} />
                </Fab>
            </div>
        </div>
    );
}

export { LandingPage };
