import type aframe from 'aframe';
import type three from 'three';

import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

declare let THREE: any;

interface ICustomPinchScaleComponent extends Partial<aframe.Component> {
    // custom properties added specificity for this component

    // initialScale is a scale multiplier applied to the model, it is needed here as it effects the upper and lower scale limits
    // e.g. if the max scale is 200% and the min scale is 50% then the limits on the max and min scale values
    // would be 2 and 0.5 respectively. If a custom scale of 0.5 is used the model would be designed to be displayed at scale 0.5
    // so the limits on min and max would be 0.25 and 1 as they would need to take into account the custom scale
    initialScale?: { x: number; y: number; z: number };
    previousDis?: number | null;

    internalState?: {
        fingerDown: boolean;
        dragging: boolean;
        distance: number;
        startDragTimeout: any;
        raycaster: THREE.Raycaster;
        positionRaw: any;
    };

    labelRenderer?: CSS2DRenderer;
    label?: HTMLElement;
    camera?: three.Camera;
    scene?: three.Scene;
    labelObj?: CSS2DObject;
    textFadeTimer?: null | NodeJS.Timeout;
    modelBoundingBox?: three.Box3;
    textOffset?: three.Vector3;

    // custom functions
    scaleModel: (xMulti: number, yMulti: number, zMulti: number) => void;
    touchMove: (e: TouchEvent) => void;
    touchEnd: () => void;
    createScaleText: () => void;
    updateScaleText: (newText: string) => void;
    startFadeOutTimer: () => void;
    clearFadeOutTimer: () => void;
    onModelLoaded: () => void;
}

// based on component from here https://github.com/8thwall/web/blob/master/xrextras/src/aframe/xr-components.js
// used to add touch pinch scaling to model
const CustomPinchScaleComponent: ICustomPinchScaleComponent = {
    schema: {
        min: { default: 0.33 },
        max: { default: 3 },
        initialScale: {
            default: { x: 0, y: 0, z: 0 },
            type: 'vec3',
        },
        multi: { default: 1.5 },
        singleAxis: { default: false, type: 'boolean' },
        enabled: { default: true, type: 'boolean' },
        showScale: { default: false, type: 'boolean' },
    },
    init() {
        const s = this.data.initialScale;
        if (this.el && this.el.sceneEl) {
            this.initialScale = s.x === 0 ? this.el.object3D.scale.clone() : s;

            this.scaleModel = this.scaleModel.bind(this);
            this.touchMove = this.touchMove.bind(this);
            this.touchEnd = this.touchEnd.bind(this);
            this.createScaleText = this.createScaleText.bind(this);
            this.updateScaleText = this.updateScaleText.bind(this);
            this.startFadeOutTimer = this.startFadeOutTimer.bind(this);
            this.clearFadeOutTimer = this.clearFadeOutTimer.bind(this);
            this.onModelLoaded = this.onModelLoaded.bind(this);

            document.addEventListener('touchmove', this.touchMove, false);
            document.addEventListener('touchend', this.touchEnd, false);

            this.el.classList.add('cantap'); // Needs "objects: .cantap" attribute on raycaster.
            if (this.data.showScale && !this.label) {
                this.createScaleText();
            }
            this.el.addEventListener('model-loaded', this.onModelLoaded);
            this.textOffset = new THREE.Vector3(0, 0, 0);
        }
    },
    remove() {
        if (this.el && this.el.sceneEl) {
            document.removeEventListener('touchmove', this.touchMove);
            document.removeEventListener('touchend', this.touchEnd);
            this.el.removeEventListener('model-loaded', this.onModelLoaded);
            if (this.labelRenderer) {
                document.body.removeChild(this.labelRenderer.domElement);
            }
            if (this.label) {
                document.body.removeChild(this.label);
            }
        }
    },
    update() {
        if (this.data.initialScale.x !== 0) {
            this.initialScale = this.data.initialScale;
            console.log('initial scale updated', this.initialScale);
        }
        if (this.data.showScale && !this.label) {
            this.createScaleText();
        }
    },
    tick() {
        if (this.el && this.labelObj && this.labelObj.position) {
            let offsetVector = THREE.Vector3.zero;
            if (this.textOffset) {
                offsetVector = this.textOffset.clone().multiply(this.el.object3D.scale);
            }
            this.labelObj.position.copy(
                new THREE.Vector3(
                    this.el.object3D.position.x + offsetVector.x,
                    this.el.object3D.position.y + offsetVector.y,
                    this.el.object3D.position.z + offsetVector.z
                )
            );
            if (this.labelRenderer && this.scene && this.camera) {
                this.labelRenderer.render(this.scene, this.camera);
            }
        }
        if (this.label) {
            if (
                !this.data.showScale ||
                (this.el?.object3D.visible === false && this.label.style.visibility !== 'hidden')
            ) {
                this.label.style.visibility = 'hidden';
            } else if (this.el?.object3D.visible === true && this.label.style.visibility !== 'visible') {
                this.label.style.visibility = 'visible';
            }
        }
    },
    scaleModel(xMulti: number, yMulti: number, zMulti: number) {
        if (this.el && this.initialScale) {
            const currentScale = this.el.object3D.scale;

            let newX = Math.max(
                Math.min(xMulti * currentScale.x, this.data.max * this.initialScale.x),
                this.data.min * this.initialScale.x
            );
            let newY = Math.max(
                Math.min(yMulti * currentScale.y, this.data.max * this.initialScale.y),
                this.data.min * this.initialScale.y
            );
            let newZ = Math.max(
                Math.min(zMulti * currentScale.z, this.data.max * this.initialScale.z),
                this.data.min * this.initialScale.z
            );
            this.el.object3D.scale.x = newX;
            this.el.object3D.scale.y = newY;
            this.el.object3D.scale.z = newZ;

            const scale = ((this.el.object3D.scale.x / this.initialScale.x) * 100).toFixed();
            this.updateScaleText(`${scale}%`);
        }
    },
    touchMove(e: TouchEvent) {
        if (e.touches.length >= 2 && this.data.enabled) {
            const p1 = e.touches[0];
            const p2 = e.touches[1];

            const disX = Math.abs(p1.clientX - p2.clientX);
            const disY = Math.abs(p1.clientY - p2.clientY);

            const disTotal = Math.sqrt(Math.pow(disX, 2) + Math.pow(disY, 2));

            if (this.previousDis) {
                const multi = 1 + ((disTotal - this.previousDis) / this.previousDis) * this.data.multi;
                if (!this.data.singleAxis) {
                    this.scaleModel(multi, multi, multi);
                } else if (disY > disX) {
                    this.scaleModel(1, multi, 1);
                } else {
                    this.scaleModel(multi, 1, 1);
                }
            }
            this.previousDis = disTotal;
        }
    },
    touchEnd() {
        this.previousDis = null;
        this.startFadeOutTimer();
    },
    createScaleText() {
        // based on 8th wall example here https://www.8thwall.com/8thwall/configurator-aframe/code/js/components.js
        this.camera = this.el?.sceneEl?.camera;
        this.scene = new THREE.Scene();
        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        this.labelRenderer.domElement.style.pointerEvents = 'none';
        document.body.appendChild(this.labelRenderer.domElement);

        this.label = document.createElement('h1');
        this.label.style.color = 'white';
        this.label.style.fontFamily = "'Nunito', sans-serif";
        this.label.style.textShadow = 'rgb(0 0 0 / 50%) 0px 0px 6px';
        this.label.style.fontWeight = 'bold';
        this.label.classList.add('custom-pinch-scale-component-scale-text');
        document.body.appendChild(this.label);

        this.labelObj = new CSS2DObject(this.label);
        if (this.scene) this.scene.add(this.labelObj);
    },
    updateScaleText(newText: string) {
        if (this.label) {
            this.label.innerText = newText;
            this.label.style.opacity = '1';
        }
    },
    startFadeOutTimer() {
        if (this.label) {
            this.clearFadeOutTimer();
            this.textFadeTimer = setTimeout(() => {
                if (this.label) {
                    this.label.style.opacity = '0';
                }
            }, 1000);
        }
    },
    clearFadeOutTimer() {
        if (this.textFadeTimer !== null && this.textFadeTimer !== undefined) {
            clearTimeout(this.textFadeTimer);
            this.textFadeTimer = null;
        }
    },
    onModelLoaded() {
        // updates the bounding box of the model when a new model loads
        if (this.el) {
            const mesh = this.el.object3D;
            let modelBoundingBox = new THREE.Box3().setFromObject(mesh, true);
            mesh.updateMatrixWorld(true); // ensure world matrix is up to date

            console.log('modelBoundingBox before', new THREE.Box3().copy(modelBoundingBox));

            //applies inverse world matrix to the bounding box to get the bounding box without transformation
            const matrixInv = new THREE.Matrix4();
            matrixInv.copy(mesh.matrixWorld).invert();

            modelBoundingBox.applyMatrix4(matrixInv);

            this.textOffset = modelBoundingBox.getCenter(new THREE.Vector3());
        }
    },
};

export { CustomPinchScaleComponent };
